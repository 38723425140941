import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fetchJurusan, patchProfile } from "../../api";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import { getJenjang, saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";


const ProfileEdit = () => {
  const token = useRecoilValue(tokenState);
  const [user, setUser] = useRecoilState(userState);
  const setTitle = useSetRecoilState(titleState);

  const [form, setForm] = useState({});
  const [jurusan, setJurusan] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const getJurusan = async () => {
    try {
      const res = await fetchJurusan("all", token);
      if (res.data) {
        setJurusan(res.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    setTitle("Ubah Profile");
    let ignore = false;

    if (!ignore) {
      getJurusan();
    }

    return () => {
      ignore = true;
    };
  }, [setTitle]);

  const handleSubmit = (e) => {
    e.preventDefault();
    patchProfile(user.id, { ...form, update_profile: true }, token)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          history.push("/");
          saAlert("success", "Berhasil update profile.");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
      <LoginAs />
      <div className="row">
        <div className="col-lg-12 mt-2 px-2">
          <div className="shadow rounded-3">
            <div className="bg-primary p-3 rounded-top-3">
              <h6 className="my-0 text-center text-white">Ubah Profile</h6>
            </div>
            <div className="p-3">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md">
                    <Input label="NIM" value={user.nim} disabled={true} />
                  </div>
                  <div className="col-md">
                    <Input label="Nama" value={user.nama} disabled={true} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Tempat Lahir"
                      name="tempat"
                      value={user.tempat}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.tempat}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Tanggal Lahir"
                      type="date"
                      name="tanggal"
                      value={user.tanggal}
                      placeholder="Tahun-Bulan-Tanggal"
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.tanggal}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Jenis Kelamin"
                      value={user.jenis}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input label="Agama" value={user.agama} disabled={true} />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Angkatan"
                      value={user.angkatan}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Jenjang"
                      value={getJenjang(user)}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    {jurusan.length > 0 &&
                      jurusan.filter((j) => parseInt(j.id) === parseInt(user.jurusan_id))
                        .map((j, index) => {
                          return (
                            <Input
                              key={index}
                              label="Jurusan"
                              value={j.nama}
                              disabled={true}
                            />
                          )
                        })
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Waktu Belajar"
                      value={user.waktu_belajar}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Semester"
                      value={user.semester}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="NO HP"
                      name="no_hp"
                      value={user.no_hp}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.no_hp}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="NO HP Ortu"
                      name="no_hp_ortu"
                      value={user.no_hp_ortu}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.no_hp_ortu}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Nama Ayah"
                      name="nama_ayah"
                      value={user.nama_ayah}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.nama_ayah}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Nama Ibu"
                      name="nama_ibu"
                      value={user.nama_ibu}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.nama_ibu}
                    />
                  </div>
                </div>
                <Textarea
                  label="Alamat Ortu"
                  name="alamat_ortu"
                  value={user.alamat_ortu}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    });
                  }}
                  error={errors.alamat_ortu}
                />
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Email"
                      name="email"
                      value={user.email}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.email}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Facebook"
                      name="fb"
                      value={user.fb}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.fb}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Instagram"
                      name="ig"
                      value={user.ig}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.ig}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Asal Sekolah"
                      value={user.asal_sekolah}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Tahut Tamat"
                      value={user.tahun_tamat}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Jurusan SMA"
                      value={user.jurusan_sma}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Informasi"
                      value={user.informasi}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Keputusan Kuliah"
                      value={user.keputusan_kuliah}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="Refrensi"
                      value={user.refrensi}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <Input
                      label="Tanggal Bergabung"
                      type="date"
                      value={user.tanggal_bergabung}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="KDBB"
                      value={user.kdbb ? "Iya" : "Tidak"}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md">
                    <Input
                      label="KTP"
                      name="ktp"
                      value={user.ktp}
                      onChange={(e) => {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        });
                      }}
                      error={errors.ig}
                    />
                  </div>
                </div>
                <div className="btn-group mobile-justify-center rounded-3 mt-3">
                  <button type="submit" className="btn btn-sm btn-primary-2 text-white">
                    Perbarui
                  </button>
                  <Link className="btn btn-sm btn-dark text-white" to="/">
                    Kembali
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
