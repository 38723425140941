import React from "react";

const Textarea = (props) => {
  const {
    id,
    label,
    name,
    value,
    rows,
    error,
    placeholder,
    readOnly,
    onChange,
    disabled,
  } = props;
  return (
    <div className="mb-3">
      {label && (
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        className={`form-control form-control-custom ${error && "is-invalid"}`}
        onKeyUp={onChange}
        onFocus={onChange}
        onPaste={onChange}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        rows={rows ? rows : 4}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

export default Textarea;
