import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPeminjamanRuangan, fetchRuangan } from "../../api";
// import Table, { Thead } from "../../components/Table";
// import Pagination from "../../components/Pagination";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth"; // tambahkan userState
import PeminjamanRuanganCreate from "./Create";
import { formatDate } from "../../helpers";
import PeminjamanRuanganEdit from "./Edit";
import LoginAs from "../../components/layouts/LoginAs";
// import { Link } from "react-router-dom/cjs/react-router-dom";
import Loading from "../../components/Loading";

const PeminjamanRuangan = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTitle("Peminjaman Ruangan");
  }, [setTitle]);

  // const user = useRecoilValue(userState);

  const [peminjamanRuangan, setPeminjamanRuangan] = useState({});

  // const [page, setPage] = useState(1);
  const [page] = useState(1);

  const fetchData = useCallback(async () => {
    const res = await fetchPeminjamanRuangan(page, token);
    setTitle(res.data.title);
    setPeminjamanRuangan(res.data.peminjaman_ruangan);
  }, [page, token, setTitle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [ruangan, setRuangan] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchRuangan("all", token);
      if (res.data) {
        setRuangan(res.data);
        setIsLoaded(true);
      }
    };

    fetchData();
  }, [token]);

  // const getClass = (pr) => {
  //   if (pr.rejected) {
  //     return "text-danger";
  //   } else if (pr.menyetujui) {
  //     return "text-success";
  //   } else if (pr.mengetahui) {
  //     return "text-warning";
  //   }
  //   return "";
  // };

  if (isLoaded) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 pt-3">
          <LoginAs />

          <div className="row g-0">
            <div className="col-lg-12 mt-3">
              <div className="bg-gradient-primary rounded-3 p-0">
                <div className="score-background rounded-top-3 shadow-nice p-4">
                  <div>
                    <h5 className="text-white fw-semibold text-center mt-2 fs-6">
                      Tips
                    </h5>
                  </div>
                </div>

                <div className="row g-0 mt-4 d-flex align-items-center">
                  <div className="col-lg-3">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div>
                        <img
                          src="/img/letter.png"
                          alt="kritik-saran.png"
                          className="p-2"
                          width={154}
                          height={154}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="px-3">
                      <ul>
                        <li className="text-gray-500 mb-2 text-sm">
                          Klik tombol dibawah untuk melakukan proses pengajuan
                          peminjaman ruangan.
                        </li>
                        <li className="text-gray-500 mb-2 text-sm">
                          Pengajuan ruangan anda akan muncul di Data peminjaman
                          anda.
                        </li>
                        <li className="text-gray-500 mb-2 text-sm">
                          Anda dapat edit data ruangan ketika belum ada persetujuan.
                        </li>
                        {/* <li className="text-gray-500 mb-2 text-sm">
                          Anda tidak dapat edit data ruangan ketika salah satu kolom{" "}
                          <span className="text-warning text-sm">
                            Mengetahui
                          </span>
                          ,{" "}
                          <span className="text-success-light text-sm">
                            Menyetujui{" "}
                          </span>
                          ,{" "}
                          <span className="text-red-light text-sm">
                            Ditolak{" "}
                          </span>{" "}
                          terisi.
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="p-3">
                      <div>
                        {/* <h5 className='text-white fs-6'>Buat Peminjaman ruangan</h5> */}
                        <PeminjamanRuanganCreate
                          fetchData={fetchData}
                          ruangan={ruangan}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" rounded-4 mx-3 p-3">
          <h1 className="fw-semibold text-center py-2 my-0 fs-5">
            Data peminjaman anda
          </h1>
        </div>

        <div className="bg-white shadow rounded-3 mx-2 px-3">
          <div className="row g-4 rounded-4 row-size pt-0 pb-4">
            {peminjamanRuangan.data && peminjamanRuangan.data.length > 0 ? (
              peminjamanRuangan.data.map((pr, index) => {
                return (
                  <div className="col-lg-6" key={index}>
                    <div className="bg-gradient-primary rounded-3 shadow-nice p-4 h-100">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                          <h5 className="text-warning fw-semibold fst-italic my-0">
                            {ruangan.length > 0 &&
                              ruangan
                                .filter((r) => parseInt(r.id) === pr.ruangan_id)
                                .map((r) => r.nama)}
                          </h5>
                        </div>
                        <div className="bg-dark rounded-3 shadow-nice py-1 px-2">
                          <i className="fa fa-fw fa-house text-warning"></i>
                        </div>
                      </div>

                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 bg-none small text-gray-500">
                              Nama
                            </td>
                            <td className="border-0 bg-none small text-white">
                              :
                            </td>
                            <td className="border-0 bg-none small text-white">
                              {pr.mhs && pr.mhs.nama}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 bg-none small text-gray-500">
                              Keperluan
                            </td>
                            <td className="border-0 bg-none small text-white">
                              :
                            </td>
                            <td className="border-0 bg-none small text-white">
                              {pr.keperluan}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 bg-none small text-gray-500">
                              Dari
                            </td>
                            <td className="border-0 bg-none small text-white">
                              :
                            </td>
                            <td className="border-0 bg-none small text-white">
                              {formatDate(pr.dari)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 bg-none small text-gray-500">
                              Sampai
                            </td>
                            <td className="border-0 bg-none small text-white">
                              :
                            </td>
                            <td className="border-0 bg-none small text-white">
                              {formatDate(pr.sampai)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 bg-none small text-gray-500">
                              No Tlpn
                            </td>
                            <td className="border-0 bg-none small text-white">
                              :
                            </td>
                            <td className="border-0 bg-none small text-white">
                              {pr.no_telp}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="row gy-3">
                        {pr.rejected ? (
                          <div className="col-lg-12">
                            <div className="shadow-nice bg-primary text-red-light rounded-3 p-3">
                              <h6 className="">Ditolak :</h6>
                              {pr.rejected ? (
                                <p className="my-0 text-white text-sm">
                                  <i className="text-red-light fa fa-fw fa-user-check text-sm me-2" />
                                  {pr.rejected}
                                </p>
                              ) : (
                                <p className="my-0 text-sm text-gray-500">
                                  <i className="fa fa-fw fa-user-clock text-sm me-2" />
                                  Belum terjawab.
                                </p>
                              )}
                            </div>
                          </div>
                        ) : pr.menyetujui ? (
                          <div className="col-lg-12">
                            <div className="shadow-nice bg-primary text-success-light rounded-3 p-3">
                              <h6 className="">Menyetujui :</h6>
                              {pr.menyetujui ? (
                                <p className="my-0 text-white text-sm">
                                  <i className="text-success-light fa fa-fw fa-user-check text-sm me-2" />
                                  {pr.menyetujui}
                                </p>
                              ) : (
                                <p className="my-0 text-sm text-gray-500">
                                  <i className="fa fa-fw fa-user-clock text-sm me-2" />
                                  Belum terjawab.
                                </p>
                              )}
                            </div>
                          </div>
                        ) : pr.mengetahui ? (
                          <div className="col-lg-12">
                            <div className="shadow-nice bg-primary text-warning rounded-3 p-3">
                              <h6 className="">Mengetahui :</h6>
                              {pr.mengetahui ? (
                                <p className="my-0 text-white text-sm">
                                  <i className="text-warning fa fa-fw fa-user-check text-sm me-2" />
                                  {pr.mengetahui}
                                </p>
                              ) : (
                                <p className="my-0 text-sm text-gray-500">
                                  <i className="fa fa-fw fa-user-clock text-sm me-2" />
                                  Belum terjawab.
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-12">
                            <div className="shadow-nice bg-primary text-white text-center rounded-3 p-3">
                              {/* <h6 className="">Menyutujui :</h6> */}
                                <p className="my-0 text-sm text-gray-500 py-2">
                                  <i className="fa fa-fw fa-user-clock text-sm me-2" />
                                  Belum ada persetujuan.
                                </p>
                            </div>
                          </div>
                        )}
                      </div>

                      {!pr.mengetahui && !pr.menyetujui && !pr.rejected ? (
                        <PeminjamanRuanganEdit
                          ruangan={ruangan}
                          peminjamanRuangan={pr}
                          fetchData={fetchData}
                        />
                      ) : (
                        <div className="text-secondary mt-4 pt-2 w-100">
                          <span className="text-sm d-flex justify-content-center align-items-center">
                            Edit tidak tersedia
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center p-5">
                <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                <p className="my-0 text-secondary small pb-5">
                  Saat ini data peminjaman ruangan masih kosong.
                </p>
              </div>
            )}
            
          </div>
        </div>
      </>
    );
  } else {
    return <Loading text="Loading. . ." />;
  }
};

export default PeminjamanRuangan;
