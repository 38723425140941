import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { postPenilaianDosen, showKelas } from "../../api";
import Radio from "../../components/Radio";
// import Table, { Thead } from "../../components/Table";
import Textarea from "../../components/Textarea";
import { saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";

const KelasPenilaianDosen = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const { kelas_id } = useParams();

  const [items, setItems] = useState({});
  const [form, setForm] = useState({});

  const fetchDataKelas = useCallback(async () => {
    try {
      const res = await showKelas(kelas_id, token);
      if (res.data) {
        setItems(res.data);

        if (res.data.penilaian_dosen) {
          setForm(res.data.penilaian_dosen[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [kelas_id, token]);

  useEffect(() => fetchDataKelas(), [fetchDataKelas]);

  useEffect(
    () => setTitle(`Penilaian Kelas ${items.kode}`),
    [setTitle, items.kode]
  );

  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    postPenilaianDosen({ ...form, kelas_id: items.id }, token, `add_row=true`)
      .then((res) => {
        if (res.data === "success") {
          setErrors({});
          fetchDataKelas();
          saAlert(
            "success",
            `Berhasil mengisi nilai Dosen kelas ${items.kode}`
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
      <LoginAs />
        <div className="row">
          <div className="col-lg-12 my-2 p-2">
            <div className="bg-gradient-primary rounded-3 shadow-nice p-0">
              <div className="score-background rounded-top-3 shadow-nice p-4">
                <div>
                  <p className="my-0 text-warning text-sm">Penilaian Kepada :</p>
                  <h5 className="my-0 text-white">
                    {items.dosen && items.dosen.nama}
                  </h5>
                  <p className="my-0 text-warning text-sm">Kelas :</p>
                  <h5 className="my-0 text-white">
                    {items.kode}
                  </h5>
                </div>
              </div>

              <div className="row pt-4 g-0">
                <div className="col-lg-12">
                  <div className="px-4">
                    <h5 className="text-white fs-6">Note</h5>
                    <ul>
                      <li className="text-gray-500 mb-2 small">
                        Pastikan pengisian nilai dilakukan dengan data yang
                        sebenarnya
                      </li>
                      <li className="text-gray-500 mb-2 small">
                        Nilai hanya dapat diisi{" "}
                        <span className="text-warning text-sm">1 kali</span>,
                        dan tidak dapat diubah
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="px-4">
                    <h5 className="text-white fs-6">Nilai dosen</h5>

                    <div className="table-responsive">
                      <table className="table table-sm table-striped table-bordered border-secondary">
                        <thead>
                          <tr>
                            <th className="text-nowrap text-center text-warning fw-semibold bg-primary small">
                              Penilaian
                            </th>
                            <th className="text-nowrap text-center text-warning fw-semibold bg-primary small">
                              Nilai
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Cara berpenampilan
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="penampilan"
                                    id="penampilan_1"
                                    checked={
                                      form && form.penampilan && form.penampilan
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="penampilan"
                                    id="penampilan_2"
                                    checked={
                                      form && form.penampilan && form.penampilan
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="penampilan"
                                    id="penampilan_3"
                                    checked={
                                      form && form.penampilan && form.penampilan
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="penampilan"
                                    id="penampilan_4"
                                    checked={
                                      form && form.penampilan && form.penampilan
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.penampilan && (
                                    <p className="text-sm text-danger m-0">
                                      Penampilan tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Kepribadian secara umum
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="kepribadian"
                                    id="kepribadian_1"
                                    checked={
                                      form &&
                                      form.kepribadian &&
                                      form.kepribadian
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="kepribadian"
                                    id="kepribadian_2"
                                    checked={
                                      form &&
                                      form.kepribadian &&
                                      form.kepribadian
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="kepribadian"
                                    id="kepribadian_3"
                                    checked={
                                      form &&
                                      form.kepribadian &&
                                      form.kepribadian
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="kepribadian"
                                    id="kepribadian_4"
                                    checked={
                                      form &&
                                      form.kepribadian &&
                                      form.kepribadian
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.kepribadian && (
                                    <p className="text-sm text-danger m-0">
                                      Kepribadian tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Penguasaan penyampaian materi
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="penguasaan_materi"
                                    id="penguasaan_materi_1"
                                    checked={
                                      form &&
                                      form.penguasaan_materi &&
                                      form.penguasaan_materi
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="penguasaan_materi"
                                    id="penguasaan_materi_2"
                                    checked={
                                      form &&
                                      form.penguasaan_materi &&
                                      form.penguasaan_materi
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="penguasaan_materi"
                                    id="penguasaan_materi_3"
                                    checked={
                                      form &&
                                      form.penguasaan_materi &&
                                      form.penguasaan_materi
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="penguasaan_materi"
                                    id="penguasaan_materi_4"
                                    checked={
                                      form &&
                                      form.penguasaan_materi &&
                                      form.penguasaan_materi
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.penguasaan_materi && (
                                    <p className="text-sm text-danger m-0">
                                      Penguasaan Materi tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Kemudahan penerimaan materi
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="mudah_diterima"
                                    id="mudah_diterima_1"
                                    checked={
                                      form &&
                                      form.mudah_diterima &&
                                      form.mudah_diterima
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="mudah_diterima"
                                    id="mudah_diterima_2"
                                    checked={
                                      form &&
                                      form.mudah_diterima &&
                                      form.mudah_diterima
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="mudah_diterima"
                                    id="mudah_diterima_3"
                                    checked={
                                      form &&
                                      form.mudah_diterima &&
                                      form.mudah_diterima
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="mudah_diterima"
                                    id="mudah_diterima_4"
                                    checked={
                                      form &&
                                      form.mudah_diterima &&
                                      form.mudah_diterima
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.mudah_diterima && (
                                    <p className="text-sm text-danger m-0">
                                      Mudah Diterima tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Semangat dalam mengajar
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="semangat_mengajar"
                                    id="semangat_mengajar_1"
                                    checked={
                                      form &&
                                      form.semangat_mengajar &&
                                      form.semangat_mengajar
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="semangat_mengajar"
                                    id="semangat_mengajar_2"
                                    checked={
                                      form &&
                                      form.semangat_mengajar &&
                                      form.semangat_mengajar
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="semangat_mengajar"
                                    id="semangat_mengajar_3"
                                    checked={
                                      form &&
                                      form.semangat_mengajar &&
                                      form.semangat_mengajar
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="semangat_mengajar"
                                    id="semangat_mengajar_4"
                                    checked={
                                      form &&
                                      form.semangat_mengajar &&
                                      form.semangat_mengajar
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.semangat_mengajar && (
                                    <p className="text-sm text-danger m-0">
                                      Semangat Mengajar tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                              Ketepatan waktu mengajar
                            </td>
                            <td className="align-middle bg-none text-white p-2">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Tidak Memuaskan"
                                    value="1"
                                    name="ketepatan_waktu"
                                    id="ketepatan_waktu_1"
                                    checked={
                                      form &&
                                      form.ketepatan_waktu &&
                                      form.ketepatan_waktu
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Kurang Memuaskan"
                                    value="2"
                                    name="ketepatan_waktu"
                                    id="ketepatan_waktu_2"
                                    checked={
                                      form &&
                                      form.ketepatan_waktu &&
                                      form.ketepatan_waktu
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Memuaskan"
                                    value="3"
                                    name="ketepatan_waktu"
                                    id="ketepatan_waktu_3"
                                    checked={
                                      form &&
                                      form.ketepatan_waktu &&
                                      form.ketepatan_waktu
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <Radio
                                    inline={true}
                                    label="Sangat Memuaskan"
                                    value="4"
                                    name="ketepatan_waktu"
                                    id="ketepatan_waktu_4"
                                    checked={
                                      form &&
                                      form.ketepatan_waktu &&
                                      form.ketepatan_waktu
                                    }
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  {errors.ketepatan_waktu && (
                                    <p className="text-sm text-danger m-0">
                                      Ketepatan Waktu tidak boleh kosong !
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="px-4">
                    <Textarea
                      name="kritik_saran"
                      placeholder="Kritik dan Saran anda . . . ."
                      value={form && form.kritik_saran && form.kritik_saran}
                      onChange={(e) =>
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="btn-group mobile-justify-center px-4 pb-4">
                <button
                  type="button"
                  className="btn btn-sm btn-primary-2"
                  onClick={() => {
                    if (
                      items.penilaian_dosen &&
                      items.penilaian_dosen.length > 0
                    ) {
                      saAlert(
                        "warning",
                        "Nilai yang sudah terisi tidak dapat diubah !"
                      );
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  Simpan
                </button>
                <Link to="/krs" type="button" className="btn btn-sm btn-dark">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KelasPenilaianDosen;
