import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { checkAuth, postLogout } from "../api";
import { saAlert } from "../helpers";
import Login from "../pages/Login";
import Maintenance from "../pages/Maintenance";
import { semesterAjaranState, tahunAjaranState } from "../storages";
import { tokenState, userState } from "../storages/auth";

const MiddlewareMhs = (props) => {
  const { children } = props;

  const [maintenance, setMaintenance] = useState(false);

  const [token, setToken] = useRecoilState(tokenState);
  const [user, setUser] = useRecoilState(userState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  // if exist token
  useEffect(() => {
    setToken(
      localStorage.getItem("_tokenMhs") ? localStorage.getItem("_tokenMhs") : ""
    );
  }, [setToken]);

  // Check token
  useEffect(() => {
    const handleLogout = async () => {
      try {
        const res = await postLogout(token)
        if (res.data === "success") {
          setToken("");
          setUser({});
          setTahunAjaran("");
          setSemesterAjaran("");
          localStorage.removeItem("_tokenMhs");
          saAlert(
            "success",
            "Akun anda sedang tidak aktif",
            "Hubungi bagian Akademik untuk mengaktifkan akun kembali."
          );
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    const checkUser = async () => {
      try {
        const res = await checkAuth(token);
        if (res.data && res.data.user && res.data.user.id) {
          setUser(res.data && res.data.user ? res.data.user : {});
          setTahunAjaran(
            res.data && res.data.tahun_ajaran ? res.data.tahun_ajaran : ""
          );
          setSemesterAjaran(
            res.data && res.data.semester_ajaran ? res.data.semester_ajaran : ""
          );
          // console.log("Is-Mhs");
        } else {
          handleLogout();
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            setToken("");
            setUser({});
            console.log("Is-Not-Mhs");
          } else if (err.response.status === 503) {
            setMaintenance(true);
          }
        }
      }
    };
    if (token !== "") {
      checkUser();
    }
  }, [token, setUser, setToken, setTahunAjaran, setSemesterAjaran]);

  // Give alert if user already
  // useEffect(() => {
  //   if (user.id) {
  //     setTimeout(() => {
  //       saAlert("success", "Berhasil login !");
  //     }, 1000);
  //   }
  // }, [user.id]);

  const isLogin = (_token) => {
    localStorage.setItem("_tokenMhs", _token);
    setToken(_token);
  };

  if (maintenance) {
    return <Maintenance />;
  } else if (token !== "") {
    if (user.id) {
      return children;
    } else {
      return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg-gradient-primary text-white">
          <div className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="loadings">
              <div className="authorization"></div>
              <div className="authorization"></div>
              <div className="authorization"></div>
            {/* <img src="/img/logo/AP-W.png" alt="" width={128} height={128} /> */}
              </div>
            </div>
            <h3>Authorization . . .</h3>
          </div>
        </div>
      );
    }
  } else {
    return <Login onIsLogin={(_token) => isLogin(_token)} />;
  }
};

export default MiddlewareMhs;
