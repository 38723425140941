import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchProgkam } from "../../api";
// import Table, { Thead } from "../../components/Table";
import { formatDate } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";
import Loading from "../../components/Loading";
import { FilterSearch } from "../../components/Filter";

const Progkam = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => setTitle("Nilai TAK"), [setTitle]);

  const [items, setItems] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchProgkam("all", token, `search=${search}`);
        setItems(res.data && res.data.progkam);

        if (res.data && res.data.progkam) {
          setItems(res.data.progkam);
          setIsLoaded(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [token, search]);

  const getNilaiTotal = () => {
    let nilai = 0;
    items.length > 0 &&
      items.map((i) => {
        return i.mhs && i.mhs.length === 1
          ? i.mhs[0].pivot && (nilai += parseInt(i.mhs[0].pivot.nilai))
          : (nilai += parseInt(i.nilai));
      });

    return nilai;
  };

  const [collapsePK, setCollapsePK] = useState(false);
  const [collapsePL, setCollapsePL] = useState(false);

  const toggleCollapsePK = () => {
    setCollapsePK(!collapsePK);
  };
  const toggleCollapsePL = () => {
    setCollapsePL(!collapsePL);
  };

  if (isLoaded) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 pt-3">
          <LoginAs />

          <div className="mt-4">
            <div className="row g-0">
              <div className="col-lg-12">
                <div className="score-background rounded-3 py-5">
                  <div className="d-flex justify-content-center align-items-center py-4">
                    <div className="text-center">
                      <h5 className="text-white">Total TAK :</h5>
                      <div className="">
                        <h5 className="my-0 px-5 display-4 fw-semibold text-warning text-shadow">
                          {getNilaiTotal()}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-4 mx-3 p-3">
          <h1 className="fw-semibold text-center py-2 my-0 fs-5">Kegiatan</h1>
        </div>

        <div className="bg-white shadow rounded-3 mx-2 mb-3">
          <div>
            <button
              className="btn btn-primary-4 border-bottom-warning text-white shadow-nice rounded-3 p-3 w-100 d-flex justify-content-between align-items-center"
              type="button"
              onClick={toggleCollapsePK}
              aria-expanded="false"
            >
              <div>
                <h6 className="my-0 fw-semibold text-warning text-start">
                  Program Kampus
                </h6>
                {collapsePK === false ? (
                  <p className="my-0 text-gray-500 text-sm px-1 pt-2 pb-1 text-start">
                    Total&nbsp;:&nbsp;
                    {items.filter((i) => i.nim_mhs === null).length} Program
                    Kampus
                  </p>
                ) : (
                  <p className="my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                    Klik untuk menutup.
                  </p>
                )}
              </div>
              {collapsePK === true ? (
                <i className="fs-5 fa fa-fw fa-caret-up" />
              ) : (
                <i className="fs-5 fa fa-fw fa-caret-down" />
              )}
            </button>
          </div>

          <div
            className={`mx-2 px-3 pb-3 pt-2 collapse ${
              collapsePK ? "show" : ""
            }`}
          >
            <div className="my-2">
              <FilterSearch value={search} onChange={(res) => setSearch(res)} />
              <p className="my-0 px-3 text-secondary text-sm">
                Search result : {items.length} items
              </p>
            </div>

            <div className="row row-size px-2 gy-3 gx-2">
              {items.length > 0 ? (
                items
                  .filter((i) => i.nim_mhs === null)
                  .map((i, index, reverses) => {
                    const reverseIndex = reverses.length - index;
                    return (
                      <CardTak
                        key={index}
                        nomer={
                          reverseIndex === reverses.length ? (
                            <>
                              {reverseIndex}
                              <span className="text-warning fw-semibold fst-italic">
                                &nbsp;Terbaru
                              </span>
                            </>
                          ) : (
                            reverseIndex
                          )
                        }
                        tak={
                          i.mhs && i.mhs.length === 1
                            ? i.mhs[0].pivot && i.mhs[0].pivot.nilai
                            : i.nilai
                        }
                        tanggal={formatDate(i.tanggal)}
                        nama={i.nama}
                        tempat={i.tempat}
                      />
                    );
                  })
              ) : (
                <div className="text-center p-5">
                  <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                  <p className="my-0 text-secondary small pb-5">
                    Saat ini belum ada data kegiatan program kampus.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-3 mx-2 mb-3">
          <div>
            <button
              className="btn btn-primary-4 border-bottom-warning text-white shadow-nice rounded-3 p-3 w-100 d-flex justify-content-between align-items-center"
              type="button"
              onClick={toggleCollapsePL}
              aria-expanded="false"
            >
              <div>
                <h6 className="my-0 fw-semibold text-warning text-start">
                  Program Lepasan
                </h6>
                {collapsePL === false ? (
                  <p className="my-0 text-gray-500 text-sm px-1 pt-2 pb-1 text-start">
                    Total&nbsp;:&nbsp;
                    {items.filter((i) => i.nim_mhs !== null).length} Program
                    Lepasan
                  </p>
                ) : (
                  <p className="my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                    Klik untuk menutup.
                  </p>
                )}
              </div>
              {collapsePL === true ? (
                <i className="fs-5 fa fa-fw fa-caret-up" />
              ) : (
                <i className="fs-5 fa fa-fw fa-caret-down" />
              )}
            </button>
          </div>

          <div
            className={`mx-2 px-3 pb-3 pt-2 collapse ${
              collapsePL ? "show" : ""
            }`}
          >
            <div className="my-2">
              <FilterSearch value={search} onChange={(res) => setSearch(res)} />
              <p className="my-0 px-3 text-secondary text-sm">
                Search result : {items.length} items
              </p>
            </div>

            <div className="row row-size px-2 gy-3 gx-2">
              {items.length > 0 &&
              items.filter((i) => i.nim_mhs !== null).length === 0 ? (
                <div className="text-center p-5">
                  <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                  <p className="my-0 text-secondary small pb-5">
                    Saat ini belum ada data kegiatan program lepasan.
                  </p>
                </div>
              ) : (
                items.length > 0 &&
                items
                  .filter((i) => i.nim_mhs !== null)
                  .map((i, index, reverses) => {
                    const reverseIndex = reverses.length - index;
                    return (
                      <CardTak
                        key={index}
                        nomer={
                          reverseIndex === reverses.length ? (
                            <>
                              {reverseIndex}
                              <span className="text-warning fw-semibold fst-italic">
                                &nbsp;Terbaru
                              </span>
                            </>
                          ) : (
                            reverseIndex
                          )
                        }
                        tak={
                          i.mhs && i.mhs.length === 1
                            ? i.mhs[0].pivot && i.mhs[0].pivot.nilai
                            : i.nilai
                        }
                        tanggal={formatDate(i.tanggal)}
                        nama={i.nama}
                        tempat={i.tempat}
                      />
                    );
                  })
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Loading text="Loading. . ." />;
  }
};

export const CardTak = (props) => {
  const { nomer, tak, tanggal, nama, tempat } = props;

  return (
    <>
      <div className="col-lg-3">
        <div className="card rounded-3 shadow border-0 h-100">
          <div className="card-header bg-primary shadow d-flex justify-content-between align-items-center">
            <div>
              <h5 className="text-white small fw-normal my-0">
                <i className="fa fa-fw fa-award me-2"></i>Kegiatan {nomer}
              </h5>
            </div>
          </div>
          <div className="card-body pb-0">
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td className="border-0 text-dark bg-none small">Nilai</td>
                  <td className="border-0 text-dark bg-none small">:</td>
                  <td className="border-0 text-dark fw-semibold bg-none small">
                    {tak}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-dark bg-none small">Tanggal</td>
                  <td className="border-0 text-dark bg-none small">:</td>
                  <td className="border-0 text-dark fw-semibold bg-none small">
                    {tanggal}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-dark bg-none small">Nama</td>
                  <td className="border-0 text-dark bg-none small">:</td>
                  <td className="border-0 text-dark fw-semibold bg-none small">
                    {nama}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-dark bg-none small">Tempat</td>
                  <td className="border-0 text-dark bg-none small">:</td>
                  <td className="border-0 text-dark fw-semibold bg-none small">
                    {tempat}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Progkam;
