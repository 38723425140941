// import React from "react";

// const Table = (props) => {
//   const { children } = props;
//   return (
//     <div className="table-responsive">
//       <table className="table table-sm table-bordered table-striped">
//         {children}
//       </table>
//     </div>
//   );
// };

// export const Thead = (props) => {
//   const { children, tr = true } = props;
//   return (
//     <thead className="bg-primary text-white text-center">
//       {tr ? <tr>{children}</tr> : children}
//     </thead>
//   );
// };

// export default Table;

import React from 'react'

function Table(props) {
    const {className, children} = props
  return (
    <>
        <table className={`table table-sm border-secondary ${className}`}>
            {children}
        </table>
    </>
  )
}

export const Thead = (props) => {
    const {children} = props
    return(
        <>
            <thead>
                {children}
            </thead>
        </>
    )
}
export const Tbody = (props) => {
    const {children} = props
    return(
        <>
            <tbody>
                {children}
            </tbody>
        </>
    )
}
export const Tr = (props) => {
    const {children} = props
    return(
        <>
            <tr className='align-middle'>
                {children}
            </tr>
        </>
    )
}
export const Th = (props) => {
    const {className, children} = props
    return(
        <>
            <th className={`text-center text-warning fw-semibold bg-primary small ${className}`}>
                {children}
            </th>
        </>
    )
}
export const Td = (props) => {
    const {className, children} = props
    return(
        <>
            <td className={`bg-none small ${className}`}>
                {children}
            </td>
        </>
    )
}

export default Table