import React from 'react'
import { useRecoilValue } from "recoil";
import { userState } from "../../storages/auth";

function LoginAs() {

    const user = useRecoilValue(userState);

  return (
    <>
        <h5 className='fs-6 my-3 text-gray-700 text-center'><i className='fa fa-fw fa-user me-1'></i>{user.nama}</h5>
    </>
  )
}

export default LoginAs