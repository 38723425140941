import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { titleState } from '../../storages';
import LoginAs from '../../components/layouts/LoginAs';
import Loading from '../../components/Loading';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { Table } from 'react-bootstrap';
import { Tbody, Td, Tr } from '../../components/Table';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSurveyMahasiswa } from '../../api';
import { tokenState } from '../../storages/auth';

function Survey() {
  const token = useRecoilValue(tokenState);
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(true);
  const [survey, setSurvey] = useState({});
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const fetchData = async () => {
    try {
      const res = await fetchSurveyMahasiswa("all", token);
      setSurvey(res.data.survey);
    } catch (err) {
      console.error(err.response);
    } finally {
      setIsLoaded(false);
    }
  }

  useEffect(() => {
    setTitle("Survey Mahasiswa");
    fetchData()
  }, []);

  console.log(survey);

  return (
    <>
      <div className="bg-white shadow rounded-3 mx-2 pt-3">
        <LoginAs />

        <div className="row g-0">
          <div className="col-lg-12 mt-3">
            <div className="bg-gradient-primary rounded-3">
              <div className="score-background rounded-top-3 shadow-nice px-3 py-4">
                <div className="row gx-4 gy-0">
                  {/* <div className="col-lg-6">
                          <div className="my-2">
                            <FilterJurusan onChange={(res) => setSearch(res)} />
                          </div>
                        </div>
      
                        <div className="col-lg-6">
                          <div className="my-2">
                            <FilterSearch onChange={(res) => setSearch(res)} />
                            <p className="my-0 px-3 text-gray-300 text-sm">Search result : {items.total} items</p>
                          </div>
                        </div> */}
                  <h6 className='mb-0 text-white text-center'><span className='text-warning'>Kuisioner</span> Mahasiswa</h6>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="px-3 pt-4">
                    <h5 className='text-white fs-6'>Tips Kuisioner</h5>
                    <p className='text-gray-500 small'>Silahkan pilih survey dibawah untuk menampilkan Kuisioner, Survey yang telah diisi akan berisi tanda centang <i className='fa fa-fw fa-circle-check text-success-light align-middle' /></p>
                    <div className='mb-3'>
                      <label className="form-label text-gray-500">Pilih Survey</label>
                      <Select
                        onChange={(e) => setSelectedSurvey(e.target.value)}
                      >
                        <option value="">Pilih</option>
                        {survey.length > 0 && survey.map((s, index) => {
                          return (
                            <option key={index} value={s.id}>{s.name}</option>
                          )
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center g-3 p-3">
          {!isLoaded ? (
            selectedSurvey ? (
              survey.filter((s) => parseInt(s.id) === parseInt(selectedSurvey)).map((s, sIndex) => 
                s.jenis_survey && s.jenis_survey.length > 0 ? (
                  s.jenis_survey.map((js, jsIndex) => {
                    return (
                      <div className="col-lg-3 col-6" key={`${sIndex}-${jsIndex}`}>
                        <div className="position-relative bg-gradient-primary rounded-3">
                          <div className="p-3">
                            <div className='position-absolute bg-primary rounded-pill' style={{ padding: '2px 4px', top: '-6px', right: '-6px' }}><i className='fa fa-fw fa-circle-xmark text-danger' /></div>
                            <h6 className='fw-normal text-white'>{js.name}</h6>
                            <Link className='btn btn-sm btn-primary-2 w-100' to={`/survey-create/${js.id}`}><i className='fa fa-fw fa-pen'></i> Beri survey</Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='col py-5 text-center' key={`${sIndex}-null`}>
                    <p className='mb-0'><i className='fa fa-fw fa-circle-exclamation'/> Jenis survey kosong.</p>
                  </div>
                )
              )
            ) : (
              <div className='col py-5 text-center'>
                <p className='mb-0'><i className='fa fa-fw fa-circle-exclamation text-info'/> Silahkan pilih survey terlebih dahulu.</p>
              </div>
            )
          ) : (
            <div className='col py-5 text-center'>
              <p className='mb-0'><i className='fa fa-fw fa-spinner fa-spin'/> Loading...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Survey