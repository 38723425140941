import React from 'react'
import { useRecoilValue } from "recoil";
import { titleState } from "../../storages";
import { Link } from "react-router-dom";
import { useFetchBeritaTerkini } from '../../pages/berita-terkini/BeritaTerkiniApi';
import { tokenState } from '../../storages/auth';
// import { userState } from "../../storages/auth";

function Container(props) {

    const {children} = props

    const title = useRecoilValue(titleState);
    const token = useRecoilValue(tokenState);
    const [items, loading] = useFetchBeritaTerkini("all", token);

  return (
    <>

        <div className='pt-4 mt-5'>
            <div className='position-relative container px-0'>

                <div className='py-3'>
                    <h1 className='fw-semibold text-center py-2 my-0 fs-5'>{title}</h1>
                </div>

                {children}


                <div className='position-fixed bottom-0 end-0'>
                    <Link to='/berita-terkini' className='position-relative d-flex justify-content-end align-items-center btn-primary-3 border-bottom-warning shadow-lg text-white text-decoration-none rounded-3 py-2 px-3 m-3'>
                        <div className='position-absolute bg-danger rounded' style={{padding: '8px 14px', top: '-6px', right: '-6px'}}>
                            <div className='position-absolute' style={{fontSize: '12px', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>
                                {!loading ? items.length : '0'}
                            </div>
                        </div>
                        <span><i className='fa fa-fw fa-border-all'/> Berita terkini</span>
                    </Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Container