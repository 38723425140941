import React from 'react'

function InputLogin(props) {
    const {
        type,
        name,
        placeholder,
        icon,
        value,
        readOnly,
        onChange,
        error,
    } = props;
  return (
    <>
        <div className='input-group input-group-lg border border-light rounded-3 mt-3'>
            <span className='input-group-text bg-none border-0'><i className={`fa fa-fw fa-${icon} text-gray-900`}></i></span>
            <input value={value} className={`form-control form-control-login-pc text-gray-900 border-0 ${error && "is-invalid"}`}
            onChange={onChange}
            onFocus={onChange}
            onMouseUp={onChange}
            onPaste={onChange}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            readOnly={readOnly}
            />
        </div>
            {error && (
              <p className='mb-0 mt-1 text-start text-danger'><i className='fa fa-fw fa-circle-exclamation'/> {error}</p>
            )}
    </>
  )
}

export default InputLogin