import React, { useEffect, useState } from 'react'
import LoginAs from '../../components/layouts/LoginAs'
import Textarea from '../../components/Textarea'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { titleState } from '../../storages';
import { tokenState, userState } from '../../storages/auth';
import { fetchSoalSurveyMahasiswa, postMhsSoalSurvey, showSoalSurveyMahasiswa } from '../../api';

function SurveyCreate() {
    const { id } = useParams();
    const user = useRecoilValue(userState);
    const token = useRecoilValue(tokenState);
    const setTitle = useSetRecoilState(titleState);
    const [isLoaded, setIsLoaded] = useState(true);
    const [soal, setSoal] = useState({});
    const pilihan = [1,2,3,4,5];
    const colors = [
        { start: '#6a1515', end: '#b52323' }, // Sangat Tidak Setuju
        { start: '#6c4010', end: '#c1711b' }, // Tidak Setuju
        { start: '#69610e', end: '#aa9e16' }, // Cukup Setuju
        { start: '#45650f', end: '#7ab11c' }, // Setuju
        { start: '#196617', end: '#26a224' }  // Sangat Setuju
    ];
    const [jawabanPilihanGanda, setJawabanPilihanGanda] = useState({});
    const [jawabanEssay, setJawabanEssay] = useState({});

    const fetchData = async () => {
        try {
          const res = await fetchSoalSurveyMahasiswa("all", token);
          setSoal(res.data.soal_survey);
        } catch (err) {
          console.error(err.response);
        } finally {
          setIsLoaded(false);
        }
      }
    
      useEffect(() => {
        setTitle("Survey Mahasiswa");
        fetchData();
      }, []);

    const handlePilihanGandaChange = (soalId, value) => {
        setJawabanPilihanGanda(prevState => ({
            ...prevState,
            [soalId]: value
        }));
    };

    const handleEssayChange = (soalId, value) => {
        setJawabanEssay(prevState => ({
            ...prevState,
            [soalId]: value
        }));
    };

    const handleSubmit = async () => {
        const jawabanArray = [];

        console.log(jawabanArray);

        // Menyusun data jawaban pilihan ganda
        for (const [soalId, jawaban_pilihan_ganda] of Object.entries(jawabanPilihanGanda)) {
            jawabanArray.push({
                soal_survey_id: soalId,
                jawaban_pilihan_ganda,
                mhs_id: user.id
            });
        }

        // Menyusun data jawaban essay
        for (const [soalId, jawaban_essay] of Object.entries(jawabanEssay)) {
            jawabanArray.push({
                soal_survey_id: soalId,
                jawaban_essay,
                mhs_id: user.id
            });
        }

        try {
            const response = await postMhsSoalSurvey({jawaban: jawabanArray}, token);
            console.log(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const arrayPilihanGanda = [
        {
            id: '1',
            pertama: 'Sangat Tidak Setuju',
            kedua: 'Tidak Setuju',
            ketiga: 'Cukup Setuju',
            keempat: 'Setuju',
            kelima: 'Sangat Setuju',
        },
        {
            id: '2',
            pertama: 'Sangat Buruk',
            kedua: 'Buruk',
            ketiga: 'Cukup',
            keempat: 'Baik',
            kelima: 'Sangat Baik',
        },
        {
            id: '3',
            pertama: 'Sangat Tidak Penting',
            kedua: 'Tidak Penting',
            ketiga: 'Cukup Penting',
            keempat: 'Penting',
            kelima: 'Sangat Penting',
        },
        {
            id: '4',
            pertama: 'Sangat Tidak Puas',
            kedua: 'Tidak Puas',
            ketiga: 'Cukup Puas',
            keempat: 'Puas',
            kelima: 'Sangat Puas',
        },
        {
            id: '5',
            pertama: 'Sangat Tidak Sering',
            kedua: 'Tidak Sering',
            ketiga: 'Cukup Sering',
            keempat: 'Sering',
            kelima: 'Sangat Sering',
        },
    ]

    return (
        <>
            <div className="bg-white shadow rounded-3 mx-2 pt-3">
                <LoginAs />

                <div className="row g-0">
                    <div className="col-lg-12 mt-3">
                        <div className="bg-gradient-primary rounded-3">
                            <div className="score-background rounded-top-3 shadow-nice px-3 py-4">
                                <div className="row gx-4 gy-0">
                                    <h6 className='mb-0 text-white text-center'>nama survey disini yaa</h6>
                                </div>
                            </div>

                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div className="px-3 pb-3 pt-4">
                                        <div className='row'>
                                            {!isLoaded ? (
                                                soal && soal.filter((s) => parseInt(s.jenis_survey_id) === parseInt(id)).length > 0 ? (
                                                    soal.filter((s) => parseInt(s.jenis_survey_id) === parseInt(id)).sort((a, b) => a.type - b.type).map((s, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {s.type === 1 &&
                                                                    <div className='col-lg-4 mb-3'>
                                                                        <label className='mb-3 text-white text-sm'>{index + 1}. {s.pertanyaan}</label>
                                                                        {pilihan.map((option) => (
                                                                            <div key={option} className='position-relative rounded-3 py-1 px-2 mb-1' style={{ background: `linear-gradient(90deg, ${colors[option - 1].start} 5%, ${colors[option - 1].end} 50%)` }}>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name={`jawaban_pilihan_ganda_${s.id}`}
                                                                                        id={`${s.id}-${option}`}
                                                                                        value={option}
                                                                                        onChange={() => handlePilihanGandaChange(s.id, option)}
                                                                                    />
                                                                                    <label className="form-check-label w-100 text-sm text-white" htmlFor={`${s.id}-${option}`}>
                                                                                        {arrayPilihanGanda.map((apg, index) => {
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    {parseInt(s.jenis_pilihan_ganda) === parseInt(apg.id) && [
                                                                                                        `${apg.pertama}`,
                                                                                                        `${apg.kedua}`,
                                                                                                        `${apg.ketiga}`,
                                                                                                        `${apg.keempat}`,
                                                                                                        `${apg.kelima}`,
                                                                                                    ][option - 1]}
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })}
                                                                                        {/* {s.jenis_pilihan_ganda === 1 && ['Sangat Tidak Setuju', 'Tidak Setuju', 'Cukup Setuju', 'Setuju', 'Sangat Setuju'][option - 1]}
                                                                                        {s.jenis_pilihan_ganda === 2 && ['Sangat Buruk', 'Buruk', 'Cukup', 'Baik', 'Sangat Baik'][option - 1]}
                                                                                        {s.jenis_pilihan_ganda === 3 && ['Sangat Tidak Penting', 'Tidak Penting', 'Cukup Penting', 'Penting', 'Sangat Penting'][option - 1]} */}
                                                                                    </label>
                                                                                </div>
                                                                                <i className='position-absolute fa fa-fw fa-sm fa-check text-gray-400' style={{ top: '1.2em', right: '0.6em' }} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                                {s.type === 2 &&
                                                                    <div className='col-lg-4 mb-3'>
                                                                        <label className='mb-3 text-white text-sm'>{index + 1}. {s.pertanyaan}</label>
                                                                        <textarea
                                                                            rows={6}
                                                                            placeholder='Ketik disini...'
                                                                            className="form-control"
                                                                            onChange={(e) => handleEssayChange(s.id, e.target.value)}
                                                                        />
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                ) : (
                                                    <>soal kosong</>
                                                )
                                            ) : (
                                                <>loading</>
                                            )}
                                        </div>

                                        <div className='btn-group btn-group-sm'>
                                            <button onClick={handleSubmit} className='btn btn-sm btn-primary-2'>Simpan</button>
                                            <Link to="/survey" className="btn btn-sm btn-dark">
                                                Kembali
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveyCreate