import { useEffect, useState } from "react";
import { fetchBeritaTerkini, showBeritaTerkini } from "../../api";

export const useFetchBeritaTerkini = (page, token, custom) => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchdata = async (page, token, custom) => {
        try {
            const res = await fetchBeritaTerkini(page, token, custom);
            setItems(res.data.berita_terkini);
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        fetchdata(page, token, custom);
    }, [page, token, custom]);

    return [items, loading, fetchdata];
};

export const useFetchBeritaTerkiniShow = (id, token, custom) => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchdata = async (id, token, custom) => {
        try {
            const res = await showBeritaTerkini(id, token, custom);
            setItems(res.data.berita_terkini);
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        fetchdata(id, token, custom);
    }, [id, token, custom]);

    return [items, loading, fetchdata];
};