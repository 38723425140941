import Swal from "sweetalert2";

export const getOrigin = () => {
  return window.location.origin === "https://sia.alfaprima.id"
    ? "https://dev.alfaprima.id"
    : "http://10.10.254.122:7777";
};

export const getCurrency = (val, n = 2, x = 0) => {
  if (val) {
    let num = parseInt(val);
    let re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  }
  return val;
};

export const getHari = (id) => {
  let data = parseInt(id);
  if (data === 1) {
    return "Senin";
  } else if (data === 2) {
    return "Selasa";
  } else if (data === 3) {
    return "Rabu";
  } else if (data === 4) {
    return "Kamis";
  } else if (data === 5) {
    return "Jumat";
  } else if (data === 6) {
    return "Sabtu";
  } else if (data === 7) {
    return "Minggu";
  }
};

export const getGrade = (nilai = false) => {
  let data = parseInt(nilai);
  if (data) {
    if (data === 1) {
      return "D";
    } else if (data === 2) {
      return "C";
    } else if (data === 3) {
      return "B";
    } else if (data === 4) {
      return "A";
    }
  }
};

export const getJurusan = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Manajemen Administrasi Rumah Sakit";
  } else if (number === 2) {
    return "Manajemen Administrasi Bisnis";
  } else if (number === 3) {
    return "Manajemen Informatika dan Komputer";
  } else if (number === 4) {
    return "Komputer Akuntansi dan Perpajakan";
  } else if (number === 5) {
    return "Desain Grafis, Multemedia, dan Animasi";
  } else if (number === 8) {
    return "Manajemen Keuangan dan Perbankan";
  } else if (number === 10) {
    return "Digital Marketing dan Komunikasi";
  }
};

export const getBulan = (bulan) => {
  let number = parseInt(bulan);
  if (number === 1) {
    return 'Januari';
  } else if (number === 2) {
    return 'Februari';
  } else if (number === 3) {
    return 'Maret';
  } else if (number === 4) {
    return 'April';
  } else if (number === 5) {
    return 'Mei';
  } else if (number === 6) {
    return 'Juni';
  } else if (number === 7) {
    return 'Juli';
  } else if (number === 8) {
    return 'Agustus';
  } else if (number === 9) {
    return 'September';
  } else if (number === 10) {
    return 'Oktober';
  } else if (number === 11) {
    return 'November';
  } else if (number === 12) {
    return 'Desember';
  }
}

export const formatDate = (date) => {
  if (date) {
    let d = String(date);
    let fullDate = d.split(" ")[0];
    let fullTime = d.split(" ")[1];
    return (
      <>
        {fullDate.split("-").reverse().join("-")}
        {/* <br /> */}
        &nbsp;
        {fullTime &&
          fullTime.split(":")[0] + "." + fullTime.split(":")[1] + " WITA"}
      </>
    );
  } else {
    return "";
  }
};

export const saAlert = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
  });
};

export const saConfirm = (icon, title, text) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Lanjut",
    cancelButtonText: "Batal",
  });
};

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const saToast = (icon, title) => {
  Toast.fire({
    icon,
    title,
  });
};

export const getJenjang = (mhs) => {
  if (mhs.jenjang === "D1") {
    return "Program 1 Tahun";
  }
  if (mhs.jenjang === "D2") {
    return "Program 2 Tahun";
  }
}