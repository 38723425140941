import React, { useEffect } from 'react'
import LoginAs from '../../components/layouts/LoginAs'
import { useRecoilState, useRecoilValue } from 'recoil'
import { titleState } from '../../storages';
import { Link } from 'react-router-dom';
import { useFetchBeritaTerkini } from './BeritaTerkiniApi';
import { getOrigin } from '../../helpers';
import { tokenState } from '../../storages/auth';
import Loading from '../../components/Loading';

function BeritaTerkini() {

    const token = useRecoilValue(tokenState);
    const [title, setTitle] = useRecoilState(titleState);
    const [items, loading] = useFetchBeritaTerkini("all", token);

    useEffect(() => {
        setTitle('Berita terkini');
    }, [title]);

  if (!loading) {
    return (
        <>

            <div className='mb-4 mt-2 pt-2'>
                <LoginAs/>
            </div>

            {items.length > 0 ?
                <>
                    <div className='col-lg-9 mx-auto'>
                        <div className='row justify-content-center px-2 mx-0 mb-2 g-2'>
                            {items.length >= 1 &&
                                items.slice(0,1).map((bt, index) => {
                                    return (
                                        <div className='col-lg-6' key={index}>
                                            <Link to={`/berita-terkini-show/${bt.id}`} className='d-block overflow-hidden rounded-3'>
                                                <div className='berita-terkini position-relative rounded-3 shadow'>
                                                    <div className='mask rounded-3'></div>
                                                    <span className='position-absolute start-0 top-0 bg-warning text-black fw-medium px-4' style={{borderRadius: '0px 0px 8px 0px', zIndex: '1'}}>Berita Terbaru</span>
                                                    <div className='bg-secondary'>
                                                        {/* <img src="/img/testImg2.jpg" loading='lazy' alt={bt.title} className='img-fluid object-fit-cover rounded-3'/> */}
                                                        <img src={`${getOrigin()}/storage/berita-terkini/picture/${bt.picture}`} loading='lazy' alt={bt.picture} className='img-fluid object-fit-cover rounded-3 text-white aspect-instagram'/>
                                                    </div>
                                                    <div className='position-absolute start-0 bottom-0 rounded-bottom-3 w-100 p-3' style={{backgroundColor: 'rgba(0, 0, 0, 0.25)'}}>
                                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                                            <p className='mb-0 text-white text-sm'><i className='fa fa-lg fa-fw fa-eye'/> {bt.berita_terkini_status && bt.berita_terkini_status.views}</p>
                                                            <p className='mb-0 text-white text-sm'><i className='ms-3 fa fa-lg fa-fw fa-heart'/> {bt.berita_terkini_status && bt.berita_terkini_status.likes}</p>
                                                        </div>
                                                        <h6 className='description mb-2 text-white'>{bt.title}</h6>
                                                        <p className='description mb-0 text-white' style={{fontSize: '12px'}}>{bt.caption}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                            
                            {items.length >= 2 &&
                                <div className='col-lg-6'>
                                    <div className='row g-2'>
                                        {items.slice(1,5).map((bt, index) => {
                                            return (
                                                <div className='col-lg-6' key={index}>
                                                    <Link to={`/berita-terkini-show/${bt.id}`} className='d-block overflow-hidden rounded-3'>
                                                        <div className='berita-terkini position-relative rounded-3 shadow'>
                                                            <div className='mask rounded-3'></div>
                                                            <div className='bg-secondary'>
                                                                {/* <img src="/img/testImg2.jpg" loading='lazy' alt={bt.title} className='img-fluid object-fit-cover rounded-3'/> */}
                                                                <img src={`${getOrigin()}/storage/berita-terkini/picture/${bt.picture}`} loading='lazy' alt={bt.picture} className='img-fluid object-fit-cover rounded-3 text-white aspect-instagram'/>
                                                            </div>
                                                            <div className='position-absolute start-0 bottom-0 rounded-bottom-3 w-100 p-3' style={{backgroundColor: 'rgba(0, 0, 0, 0.25)'}}>
                                                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                                                    <p className='mb-0 text-white text-sm'><i className='fa fa-lg fa-fw fa-eye'/> {bt.berita_terkini_status && bt.berita_terkini_status.views}</p>
                                                                    <p className='mb-0 text-white text-sm'><i className='ms-3 fa fa-lg fa-fw fa-heart'/> {bt.berita_terkini_status && bt.berita_terkini_status.likes}</p>
                                                                </div>
                                                                <h6 className='text-sm description mb-2 text-white'>{bt.title}</h6>
                                                                <p className='description mb-0 text-white' style={{fontSize: '11px'}}>{bt.caption}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
    
                        <div className='row px-2 mx-0 mb-2 g-2'>
                            {items.length >= 6 &&
                                items.slice(5,9).map((bt, index) => {
                                    return (
                                        <div className='col-lg-3' key={index}>
                                            <Link to={`/berita-terkini-show/${bt.id}`} className='d-block overflow-hidden rounded-3'>
                                                <div className='berita-terkini position-relative rounded-3 shadow'>
                                                    <div className='mask rounded-3'></div>
                                                    <div className='bg-secondary'>
                                                        {/* <img src="/img/testImg2.jpg" loading='lazy' alt={bt.title} className='img-fluid object-fit-cover rounded-3'/> */}
                                                        <img src={`${getOrigin()}/storage/berita-terkini/picture/${bt.picture}`} loading='lazy' alt={bt.picture} className='img-fluid object-fit-cover rounded-3 text-white aspect-instagram'/>
                                                    </div>
                                                    <div className='position-absolute start-0 bottom-0 rounded-bottom-3 w-100 p-3' style={{backgroundColor: 'rgba(0, 0, 0, 0.25)'}}>
                                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                                            <p className='mb-0 text-white text-sm'><i className='fa fa-lg fa-fw fa-eye'/> {bt.berita_terkini_status && bt.berita_terkini_status.views}</p>
                                                            <p className='mb-0 text-white text-sm'><i className='ms-3 fa fa-lg fa-fw fa-heart'/> {bt.berita_terkini_status && bt.berita_terkini_status.likes}</p>
                                                        </div>
                                                        <h6 className='text-sm description mb-2 text-white'>{bt.title}</h6>
                                                        <p className='description mb-0 text-white' style={{fontSize: '11px'}}>{bt.caption}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
                :
                <div className="nice-background rounded-3 mx-2">
                    <div className="row g-0 d-flex justify-content-center custom-hue">
                        <div className="col-lg-6">
                            <div className="text-center p-4">
                                <i className="fa fa-fw fa-bullhorn text-white fs-3 mb-3 pt-5"></i>
                                <h5 className="text-white">Hi, Mahasiswa!</h5>
                                <p className="my-0 text-gray-500 small pb-4">
                                    Ops... saat ini belum ada berita terkini, berita terkini akan segera hadir dalam waktu dekat, tetap pantau yaa agar kalian tidak ketinggalan informasi-informasi bermanfaat. semangat!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
      )
  } else {
    return <Loading/>;
  }
}

export default BeritaTerkini