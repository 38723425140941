import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showProfile } from "../../api";
import { formatDate, getOrigin } from "../../helpers"; // tambahkan getOrigin
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";
import Table, { Tbody, Td, Tr } from "../../components/Table";
import Loading from "../../components/Loading";

const Profile = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTitle("Profile Mahasiswa");
  }, [setTitle]);

  const user = useRecoilValue(userState);

  const [mhs, setMhs] = useState({});

  useEffect(() => {
    const showDataMhs = async () => {
      try {
        const res = await showProfile(user.id, token);
        if (res.data) {
          setMhs(res.data.mhs);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (token && user.id) {
      showDataMhs();
    }
  }, [token, user.id]);

  const [collapseDisc, setCollapseDisc] = useState(false);

  const toggleCollapseDisc = () => {
    setCollapseDisc(!collapseDisc);
  };

  return (
    <>
      <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
        <LoginAs />
        <div className="row">
          <div className="col-lg-12 mt-2 px-2">
            <div className="bg-gradient-primary rounded-3 p-0">
              {/* MAIN EVENT */}
              <div className="score-background rounded-top-3 shadow-nice p-4">
                <div>
                  <div>
                    <p className="my-0 text-center text-white text-shadow fw-semibold fst-italic fs-5">
                      TO BE THE{" "}
                      <span className="text-warning fs-5">FIRST CHOICE</span> OF
                      INDUSTRY
                    </p>
                  </div>
                </div>
              </div>
              {/* END MAIN EVENT */}

              <div className="row g-0 d-flex align-items-center">
                <div className="col-lg-3 px-5 my-4">
                  <div className="d-flex justify-content-center align-items-center img-responsive">
                    {isLoaded ? (
                      <img
                      src={`${getOrigin()}/storage/foto_mahasiswa/${
                        user.cabang
                      }/${user.angkatan}/${user.nim}.jpg`}
                      alt="Tidak ada foto"
                      className="img-fluid rounded-3 shadow-profile text-gray-500"
                    />
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>

                <div className="col-lg-9 px-4 pt-4">
                  <Table className="table-borderless">
                    <Tbody>
                      <Tr>
                        <Td className="text-gray-500 small">NIM</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">{user.nim}</Td>
                      </Tr>
                      <Tr>
                        <Td className="text-gray-500 small">Nama</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">{user.nama}</Td>
                      </Tr>
                      <Tr>
                        <Td className="text-gray-500 small">Tempat Lahir</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">{user.tempat}</Td>
                      </Tr>
                      <Tr>
                        <Td className="text-gray-500 small">Tanggal Lahir</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">
                          {formatDate(user.tanggal)}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td className="text-gray-500 small">No HP</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">{user.no_hp}</Td>
                      </Tr>
                      <Tr>
                        <Td className="text-gray-500 small">Alamat</Td>
                        <Td className="text-white small">:</Td>
                        <Td className="text-white small">{user.alamat_ortu}</Td>
                      </Tr>
                    </Tbody>
                  </Table>

                  <div className="btn-group mobile-justify-center shadow mb-4">
                    <Link
                      className="btn btn-sm btn-primary-2 px-4"
                      to="/profile-edit"
                    >
                      <i className="fa fa-fw fa-pen-to-square"></i> Ubah Profile
                    </Link>
                    <Link
                      className="btn btn-sm btn-warning-2 px-4"
                      to="update-password"
                    >
                      <i className="fa fa-fw fa-key text-dark"></i> Ganti
                      Password
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bg-light rounded-3" id="testDisc">
                    <div className="border-0 shadow">
                      <div className="bg-primary text-white text-center rounded shadow">
                        <button
                          className="w-100 border-0 py-2 btn-primary-3 rounded-3"
                          type="button"
                          onClick={toggleCollapseDisc}
                          aria-expanded={collapseDisc ? "true" : "false"}
                          aria-controls="collapseDisc"
                        >
                          <span className="d-flex justify-content-center align-items-center small">
                            <i className="fa fa-fw fa-book-open me-2" /> Hasil
                            Tes DISC (Click to {collapseDisc ? "Hide" : "View"})
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseDisc"
                        className={`collapse-nice ${
                          collapseDisc ? "show-nice" : ""
                        }`}
                        data-bs-parent="#testDisc"
                      >
                        <div className="rounded-3 p-3">
                          <div className="row g-2">
                            <div className="col-lg-6">
                              <div className="card border-0 h-100">
                                <div className="card-header border-0 text-center">
                                  <h5 className="my-0 fs-6 fw-semibold">
                                    Tes DISC 1
                                  </h5>
                                </div>

                                {mhs.disc && mhs.disc.disc_1 ? (
                                  <div className="card-body bg-silver p-0">
                                    <div className="ratio ratio-1x1 h-100">
                                      <iframe
                                        title="disc_1"
                                        src={`${getOrigin()}/storage/disc_mhs/disc_1_${user.nim}.pdf`}
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card-body bg-silver d-flex flex-column justify-content-center align-items-center">
                                    <div className="text-center p-5">
                                      <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                                      <p className="my-0 text-secondary small pb-5">
                                        Saat ini belum ada data hasil test DISC
                                        ke 1
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card border-0 h-100">
                                <div className="card-header border-0 text-center">
                                  <h5 className="my-0 fs-6 fw-semibold">
                                    Tes DISC 2
                                  </h5>
                                </div>

                                {mhs.disc && mhs.disc.disc_2 ? (
                                  <div className="card-body bg-silver p-0">
                                    <div className="ratio ratio-1x1 h-100">
                                      <iframe
                                        title="disc_2"
                                        src={`${getOrigin()}/storage/disc_mhs/disc_2_${user.nim}.pdf`}
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card-body bg-silver d-flex flex-column justify-content-center align-items-center">
                                    <div className="text-center p-5">
                                      <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                                      <p className="my-0 text-secondary small pb-5">
                                        Saat ini belum ada data hasil test DISC
                                        ke 2
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
          show={showB}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size='lg'
          contentClassName='bg-gradient-primary-3'
      >
          <Modal.Header 
              className='d-flex justify-content-center border-secondary'
          >
              <Modal.Title>
                  <div className="navbar-brand text-white d-flex align-items-center p-0">
                      <img src="/img/logo/AP-W.png" alt="" width={48}/>
                      <span className="fs-5">ALFA PRIMA</span>
                  </div>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-gray-500'>
              <h5 className="text-center text-white">Pengumuman 2</h5>

              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque nihil, repellendus modi quasi in at minus est inventore quisquam ipsa quidem vitae optio error voluptates.</p>

              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate, neque? Quos tenetur, expedita labore provident placeat perspiciatis magni aut odit quasi omnis illo incidunt sunt deleniti soluta distinctio laudantium minus nobis qui iure quia recusandae porro sit? Doloremque harum sunt soluta magni sit tenetur aut recusandae, beatae repellat eveniet porro.</p>

              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem, fuga!</p>
          </Modal.Body>
          <Modal.Footer className='text-white border-secondary'>
          <Button 
              size='sm'
              onClick={handleCloseB}
              className='btn-primary-2'
          >
              Oke, mengerti
          </Button>
          </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Profile;
