import React from "react";

const Maintenance = () => {
  return (
    <>
    <div className="bg-gradient-primary text-white d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="container col-lg-4 bg-primary shadow rounded-3 p-4">
        <div className="text-center">
          <img src="/img/logo/alfaprima_white.png" alt="none" width={154} />
            {/* <h1 className='my-0 text-white fst-alfaprima fs-2'>ALFA PRIMA</h1> */}
        </div>

        <hr className="mb-4"/>

        <div className="text-center">
          <i className="fa fa-fw fa-triangle-exclamation text-warning fs-1 mb-2"/>
          <p className="fw-light text-gray-500">
            Mohon maaf website sedang dalam proses pemeliharaan, website dapat diakses kembali untuk sesegera mungkin.
          </p>
          <p className="mb-1 mt-4">Ikuti kami untuk informasi terkini</p>
          <i className="text-white fa fa-arrow-down"/>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button             type="button"
            onClick={() => {
              window.open("http://instagram.com/alfaprimacampus");
            }} 
            className="btn btn-primary-3 shadow rounded-3 mx-1 d-flex align-items-center">
              <i className="bi bi-instagram fs-4 me-2"></i>
              Instagram
            </button>
            <button 
              type="button"
              onClick={() => {
                window.open("http://facebook.com/campus.alfa.prima");
              }}
            className="btn btn-primary-3 shadow rounded-3 mx-1 d-flex align-items-center">
              <i className="bi bi-facebook fs-4 me-2"></i>
              Facebook
            </button>
          </div>
        </div>

        <div className="text-center mt-4">
          <p className="my-0 text-gray-500 fw-light">Copyright © Alfa Prima {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Maintenance;
