import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { postPeminjamanRuangan } from "../../api";
import Input from "../../components/Input";
// import Modal from "../../components/Modal";
import Select from "../../components/Select";
import Textarea from "../../components/Textarea";
import { saAlert } from "../../helpers";
import { tokenState } from "../../storages/auth";
import { Modal } from "react-bootstrap";

const PeminjamanRuanganCreate = ({ fetchData, ruangan }) => {
  const token = useRecoilValue(tokenState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postPeminjamanRuangan(form, token);
      console.log(res.data);
      if (res.data && res.data.status) {
        fetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className="btn btn-sm btn-primary-2 py-2 w-100"
      >
        <i className="fa fa-fw fa-paper-plane"></i> Klik disini
        untuk Meminjam ruangan
      </button>

      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        contentClassName="border-0 rounded-4"
      >
        <Modal.Header 
          data-bs-theme='dark' 
          className="border-0 score-background shadow rounded-top-4" 
          closeButton
        >
          <Modal.Title className="fs-5 text-white">Tambah Data Peminjaman Ruangan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} ref={tagForm}>
            <div className="row">
              <div className="col-lg-4">
                <Select
                  label="Ruangan"
                  name="ruangan_id"
                  onChange={handleChange}
                  error={errors.ruangan_id}
                >
                  <option value="">Pilih</option>
                  {ruangan &&
                    ruangan.length > 0 &&
                    ruangan.map((r, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={r.id}>{r.nama}</option>
                        </React.Fragment>
                      );
                    })}
                </Select>
              </div>
              <div className="col-lg-4">
                <Input
                  label="Dari"
                  type="datetime-local"
                  name="dari"
                  onChange={handleChange}
                  error={errors.dari}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Sampai"
                  type="datetime-local"
                  name="sampai"
                  onChange={handleChange}
                  error={errors.sampai}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="No Telp"
                  name="no_telp"
                  onChange={handleChange}
                  error={errors.no_telp}
                />
              </div>
              <div className="col-lg-8">
                <Textarea
                  label="Keperluan"
                  name="keperluan"
                  onChange={handleChange}
                  error={errors.keperluan}
                />
              </div>
            </div>
            <div className="btn-group mobile-justify-center rounded-3 mt-3">
              <button type="submit" className="btn btn-sm btn-primary-2 text-white">
                Simpan
              </button>
              <button type="button" className="btn btn-sm btn-dark text-white" onClick={handleClose}>
                Kembali
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PeminjamanRuanganCreate;
