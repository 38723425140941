import React, { useState } from "react";
import { loginAuth } from "../api";
// import Input from "../components/Input";
import { saAlert } from "../helpers";
import InputLogin from "../components/InputLogin";

const Login = (props) => {
  const { onIsLogin } = props;

  const [form, setForm] = useState({ is_mhs: true });
  const [errors, setErrors] = useState({});

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await loginAuth(form);
      if (res.data) {
        if (res.data.message === "danger") {
          // Dobel NIM
          saAlert(
            "warning",
            "Akun error segera hubungi bagian Akademik dan Kemahasiswaan !",
            "Hubungi bagian Akademik dan Kemahasiswaan agar akun anda dapat segera diperbaiki !"
          );
        } else if (res.data.message === "inactive") {
          // Fail
          saAlert(
            "warning",
            "Akun anda sedang tidak aktif",
            "Hubungi bagian Akademik untuk mengaktifkan akun kembali."
          );
        } else if (res.data.message === "fail") {
          // Fail
          saAlert(
            "warning",
            "NIM atau password yang anda masukkan salah !",
            "Cek ulang kembali NIM dan password yang anda gunakan."
          );
        } else if (res.data.message === "success") {
          // Success
          let _token = res.data._token.split("|")[1];
          localStorage.setItem("_tokenMhs", _token);
          window.location.reload();
          onIsLogin(_token);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      }
    }
  };

  return (
    <>
    {/* <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="row w-100 justify-content-center">
        <form
          onSubmit={handleLogin}
          className="col-md-10 col-sm-12 col-lg-6 col-xl-5 p-5 rounded shadow text-center bg-white"
        >
          <img
            src="https://sim.alfaprima.id/logo.jpg"
            className="img-fluid w-25"
            alt=""
          />
          <h4>Login SIA - Alfa Prima</h4>
          <Input
            name="nim"
            placeholder="NIM"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.nim}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.password}
          />
          <button className="btn btn-primary w-100 rounded-pill">Login</button>
        </form>
      </div>
    </div> */}






    <div>
            <div className='mobile-hidden'>
                <div className='row g-0'>
                    <div className='col-lg-4 alfa-background shadow p-3'>
                        <div className='d-flex flex-column h-100 rounded-3 py-4'>
                            <div className='m-auto'>
                                <div className='text-center'>
                                    <img src="img/logo/alfaprima_white.png" alt="alfaprima.png" width={154}/>
                                </div>
                            </div>
                            <div className='m-auto pb-5 pt-3'>
                                <div className='text-center'>
                                    <h5 className='mb-1 text-warning fs-4'>Selamat datang di SIA</h5>
                                    <p className='mb-0 text-gray-400'>Sistem Informasi Akademik Alfa Prima</p>
                                </div>
                            </div>
                            <div className='m-auto pt-3'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <p className='mb-0 text-gray-500 text-sm'>Copyright &copy; Alfa Prima 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-8 bg-white'>
                        <div className='d-flex justify-content-center vh-100'>
                            <div className='col-lg-6 d-flex flex-column h-100'>
                                <div className='m-auto'>
                                    <h5 className='text-center text-primary fw-semibold fs-3'>Login</h5>
                                    <p className='mb-0 text-gray-600 text-center'>Masukan NIM dan pasword anda.</p>
                                </div>
                                <div className=''>
                                    <form onSubmit={handleLogin} className='py-0'>
                                        <InputLogin
                                          name="nim"
                                          icon='user'
                                          placeholder="NIM"
                                          onChange={(e) => {
                                            setForm((prevState) => {
                                              return { ...prevState, [e.target.name]: e.target.value };
                                            });
                                          }}
                                          error={errors.nim}
                                        />
                                        <InputLogin
                                          name="password"
                                          icon='key'
                                          type="password"
                                          placeholder="Password"
                                          onChange={(e) => {
                                            setForm((prevState) => {
                                              return { ...prevState, [e.target.name]: e.target.value };
                                            });
                                          }}
                                          error={errors.password}
                                        />
                                        <div>
                                            <button className='btn btn-lg btn-primary-3 shadow rounded-3 mt-3 w-100' type="submit"><i className='fa fa-fw fa-arrow-alt-circle-right'></i> Login</button>
                                        </div>
                                    </form>
                                </div>

                                <div className='m-auto'>
                                    <div className='text-center'>
                                        <p className='mb-0 text-gray-600'>Made by <i className='fa fa-fw fa-heart text-danger'></i> IT ALFA PRIMA 2023</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mobile-show'>
                <div className='bg-gradient-primary vh-100'>
                    <div className='d-flex flex-column h-100 shadow-nice rounded-3 py-4'>
                        <div className='m-auto'>
                            <div className='text-center'>
                                <img src="img/logo/alfaprima_white.png" alt="alfaprima.png" width={154} />
                            </div>
                        </div>
                        <div className='m-auto pb-5 pt-3'>
                            <div className='container col-lg-12 p-4 text-center'>
                                <h5 className='mb-1 text-warning fw-semibold fs-1'>Login</h5>
                                <p className='mb-0 text-gray-400 text-sm'>Masukan NIM dan pasword anda.</p>

                                <div className='mt-4'>
                                    <form onSubmit={handleLogin} className='py-0'>
                                        <InputLogin
                                          name="nim"
                                          icon='user'
                                          placeholder="NIM"
                                          onChange={(e) => {
                                            setForm((prevState) => {
                                              return { ...prevState, [e.target.name]: e.target.value };
                                            });
                                          }}
                                          error={errors.nim}
                                        />
                                        <InputLogin
                                          name="password"
                                          icon='key'
                                          type="password"
                                          placeholder="Password"
                                          onChange={(e) => {
                                            setForm((prevState) => {
                                              return { ...prevState, [e.target.name]: e.target.value };
                                            });
                                          }}
                                          error={errors.password}
                                        />
                                        <div>
                                            <button className='btn btn-lg btn-primary-3 shadow rounded-3 mt-3 w-100' type="submit"><i className='fa fa-fw fa-arrow-alt-circle-right'></i> Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='m-auto pt-5'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='mb-0 text-gray-500 text-sm'>Copyright &copy; Alfa Prima 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Login;
