import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchBukuPerpustakaan } from "../../api";
import { FilterJurusan, FilterSearch } from "../../components/Filter";
import Pagination from "../../components/Pagination";
import Table, { Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import { getJurusan } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";
import Loading from "../../components/Loading";

const BukuPerpustakaan = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTitle("Data Buku Perpustakaan");
  }, [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchBukuPerpustakaan(page, token, `search=${search}`);
      if (res.data) {
        setItems(res.data.buku_perpustakaan);
        setIsLoaded(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, token, search]);

  useEffect(() => fetchData(), [fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 pt-3">
          <LoginAs />
  
          <div className="row g-0">
            <div className="col-lg-12 mt-3">
              <div className="bg-gradient-primary rounded-3">
                <div className="score-background rounded-top-3 shadow-nice px-3 py-4">
                  <div className="row gx-4 gy-0">
                    <div className="col-lg-6">
                      <div className="my-2">
                        <FilterJurusan onChange={(res) => setSearch(res)} />
                      </div>
                    </div>
  
                    <div className="col-lg-6">
                      <div className="my-2">
                        <FilterSearch onChange={(res) => setSearch(res)} />
                        <p className="my-0 px-3 text-gray-300 text-sm">Search result : {items.total} items</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="row g-0">
                  <div className="col-lg-12">
                    <div className="px-3 pt-4">
                      {/* <h5 className='text-white fs-6'>Data Buku</h5>
                                              <p className='text-gray-500 small'>Pada tampilan mobile anda dapat menggeser tabel ke kiri untuk melihat data lebih rinci.</p> */}
  
                      <div className="table-responsive">
                        <Table className="table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>No</Th>
                              <Th>Kode</Th>
                              <Th>Judul</Th>
                              <Th>Jurusan</Th>
                              <Th>Penerbit</Th>
                              <Th>Pengarang</Th>
                              <Th>Halaman</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {items.data &&
                              items.data.length > 0 &&
                              items.data.map((i, index) => {
                                return (
                                  <Tr key={index}>
                                    <Td className="text-nowrap text-white text-center">
                                      {items.from + index}
                                    </Td>
                                    <Td className="text-nowrap text-white text-center">
                                      {i.kode}
                                    </Td>
                                    <Td className="text-nowrap text-white">
                                      {i.judul}
                                    </Td>
                                    <Td className="text-nowrap text-white">
                                      {getJurusan(i.jurusan_id)}
                                    </Td>
                                    <Td className="text-nowrap text-white">
                                      {i.penerbit}
                                    </Td>
                                    <Td className="text-nowrap text-white">
                                      {i.pengarang}
                                    </Td>
                                    <Td className="text-nowrap text-white text-center">
                                      {i.halaman}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
  
                      {items.links && items.links.length > 0 && (
                        <Pagination
                          links={items.links}
                          onChange={(res) => setPage(res)}
                        />
                      )}
                      </div>
  
  
                      {/* <div className='d-flex justify-content-center align-items-center'>
                                                  <nav>
                                                      <ul className="pagination pagination-sm">
                                                          <li className="page-item disabled">
                                                              <a className="page-link bg-none border-0 rounded text-gray-500">Previous</a>
                                                          </li>
                                                          <li className="page-item active" aria-current="page">
                                                              <a className="page-link rounded border-0 shadow-nice text-white" href="#">1</a>
                                                          </li>
                                                          <li className="page-item"><a className="page-link bg-none border-0 rounded text-white" href="#">2</a></li>
                                                          <li className="page-item"><a className="page-link bg-none border-0 rounded text-white" href="#">3</a></li>
                                                          <li className="page-item">
                                                              <a className="page-link bg-none border-0 rounded text-white" href="#">Next</a>
                                                          </li>
                                                      </ul>
                                                  </nav>
                                              </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return(
      <Loading text="Loading. . ." />
    );
  }
};

export default BukuPerpustakaan;
