import React, { useEffect } from 'react'
import LoginAs from '../../components/layouts/LoginAs'
import { useFetchBeritaTerkiniShow } from './BeritaTerkiniApi';
import { useParams } from "react-router";
import { useRecoilValue } from 'recoil';
import { tokenState, userState } from '../../storages/auth';
import { getOrigin } from '../../helpers';
import Loading from '../../components/Loading';
import { patchBeritaTerkini } from '../../api';

function BeritaTerkiniShow() {
  const { id } = useParams();
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [items, loading, fetchdata] = useFetchBeritaTerkiniShow(id, token);

  const setLike = async () => {

    const data = {
      is_sia: true,
      mhs_id: user.id,
      is_like: true,
    }

    try {
      const res = await patchBeritaTerkini(id, data, token);
      fetchdata(id, token);
    } catch (error) {
        console.log(error.response);
    }
  }

  const setView = async () => {

    const data = {
      is_sia: true,
      mhs_id: user.id,
      is_view: true,
    }

    try {
      const res = await patchBeritaTerkini(id, data, token);
      fetchdata(id, token);
    } catch (error) {
        console.log(error.response);
    }
  }

  useEffect(() => {
    setView();
  }, []);

  if (!loading) {
    return (
      <div className="bg-white shadow rounded-3 mx-2 pt-3">
        <LoginAs />
        <div className="row g-0">
          <div className="col-lg-12 mt-3">
            <div className='px-3 pb-3'>
              <div className='row'>
                <div className='col-lg-6'>
                  <img src={`${getOrigin()}/storage/berita-terkini/picture/${items.picture}`} loading='lazy' alt={items.picture} className='object-fit-cover mb-4 img-fluid rounded-3' />
                </div>
                <div className='col-lg-6'>
                  <div className='py-lg-3'>
                    <h2 className='mb-4 h2 fw-semibold description'>{items.title}</h2>
                    <p className='description mb-4'>{items.caption}</p>
                    <div className='d-flex align-items-center'>
                      <button onClick={setLike} className='btn btn-sm border-0'>
                        {items.berita_terkini_status_likes.filter((like) => like.mhs_id === user.id).length > 0 ?
                          <i className='fa fa-fw fa-heart text-danger' />
                          :
                          <i className='fa fa-regular fa-fw fa-heart' />
                        }
                        <span className='ms-1'>{items.berita_terkini_status && items.berita_terkini_status.likes}</span>
                      </button>
                      <p className='mb-0 ms-2'><i className='fa fa-fw fa-eye' /> {items.berita_terkini_status && items.berita_terkini_status.views}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Loading />;
  }

}

export default BeritaTerkiniShow