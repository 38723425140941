import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { postKs } from "../../api";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import { saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";

const KsCreate = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);

  useEffect(() => setTitle("Buat Kritik & Saran"), [setTitle]);

  const [form, setForm] = useState({
    no_hp: "",
    bagian: "",
    judul: "",
    isi: "",
    foto: "",
  });

  const [errors, setErrors] = useState({});

  const tagForm = useRef();

  const [img, setImg] = useState("");
  const [imgName, setImgName] = useState("Kosong");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("foto", form.foto);

    postKs(
      form.foto !== '' && formData,
      token,
      `no_hp=${form.no_hp}&bagian=${form.bagian}&judul=${form.judul}&isi=${form.isi}&is_mhs=true`
    )
      .then((res) => {
        if (res.data === "success") {
          tagForm.current && tagForm.current.reset();
          setForm({
            no_hp: "",
            bagian: "",
            judul: "",
            isi: "",
            foto: "",
          });
          setImg("");
          setErrors({});
          saAlert("success", "Berhasil kirim data kritik dan saran !");
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
<div className='bg-white shadow rounded-3 mx-2 pt-3'>

<LoginAs />

<div className='row g-0'>
    <div className='col-lg-12 mt-3'>
        <div className='bg-gradient-primary rounded-3 p-0'>
            <div className='score-background rounded-top-3 shadow-nice px-4 pb-2 pt-4'>
                {/* <div>
                    <h5 className='my-0 text-warning-animation fst-italic fs-3 fw-semibold'>Selamat datang di perpustakaan!</h5>
                </div> */}

                <div className='row'>
                    <div className='col-lg-3'>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div>
                                <img src="/img/chat.png" alt="kritik-saran.png" className='p-2' width={154} height={154} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        <div>
                            <h5 className='text-white fs-6'>Note</h5>
                            <ul>
                                <li className='text-gray-500 mb-2 small'>Setiap Mahasiswa aktif berhak mengirimkan kritik dan saran pada halaman ini.</li>
                                <li className='text-gray-500 mb-2 small'>Silahkan isikan kritik dan saran dengan bahasa yang baik.</li>
                                <li className='text-gray-500 mb-2 small'>Kritik dan saran anda sangat kami harapkan demi kemajuan Manajemen.</li>
                                <li className='text-gray-500 mb-2 small'>Identidas pengirim data akan sangat dirahasiakan dan tidak akan diketahui oleh siapapun</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <form onSubmit={handleSubmit} ref={tagForm}>
                <div className='row g-0 px-2 pb-1 pt-4'>
                    <div className='col-lg-4'>
                      <div className="px-2">
                        <label className="form-label text-gray-500">Judul kritik & saran</label>
                        <Input
                          name="judul"
                          placeholder="Judul"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return { ...prevState, [e.target.name]: e.target.value };
                            })
                          }
                          error={errors.judul}
                        />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="px-2">
                      <label className="form-label text-gray-500">No HP (Optional)</label>
                        <Input
                          name="no_hp"
                          placeholder="+62"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return { ...prevState, [e.target.name]: e.target.value };
                            })
                          }
                          error={errors.no_hp}
                        />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="px-2">
                      <label className="form-label text-gray-500">Bagian Yang Dituju (Optional)</label>
                        <Input
                          name="bagian"
                          placeholder="Kepada"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return { ...prevState, [e.target.name]: e.target.value };
                            })
                          }
                          error={errors.bagian}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className="px-2">
                      <label className="form-label text-gray-500">Isi</label>
                        <Textarea
                          name="isi"
                          placeholder="Kritik & saran"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return { ...prevState, [e.target.name]: e.target.value };
                            })
                          }
                          error={errors.isi}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                    <label htmlFor="cariBook" className="form-label text-gray-500 px-2">Foto (Optional)</label>
                        {img ? (
                          <div className="col-lg-3">
                            <div className="px-2">
                              <div className="rounded-3 bg-white">
                                <img src={img} className="img-fluid w-100 rounded-top-3 shadow" alt="" />
                                <div className="d-flex justify-content-between align-items-center p-2">
                                  <p className="my-0 text-sm">{imgName}</p>
                                  <button
                                    onClick={() => {
                                      setImg(null)
                                      setImgName("Kosong")
                                    }} 
                                    className="bg-none border-0"
                                    >
                                    <i className="fa fa-fw fa-trash"/>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-3">
                            <div className="px-2">
                              <div className="rounded-3 custom-file-button p-1" 
                                onClick={() => document.querySelector(".input-foto").click()}>
                                <div className="box-image rounded-3">
                                  <div className="text-center">
                                    <i className="fa fa-fw fa-image fs-1" />
                                    <p className="my-0 text-sm mt-2">Klik disini untuk upload foto.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='px-2'>
                            <div className='mb-3'>
                                <Input
                                  className="input-foto"
                                  hidden={true}
                                  name="foto"
                                  type="file"
                                  onChange={(e) => {
                                    if (e.target.files[0]) {
                                      setImg(URL.createObjectURL(e.target.files[0]));
                                      setImgName(e.target.files[0].name);
                                      setForm((prevState) => {
                                        return { ...prevState, [e.target.name]: e.target.files[0] };
                                      }); 
                                    }
                                  }}
                                  error={errors.foto}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='px-2'>
                            <div className='mb-3'>
                                <button type="submit" className='btn btn-sm btn-primary-2 fw-semibold py-2 w-100'><i className='fa fa-fw fa-paper-plane'></i> Kirim</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>

    </div>

</div>      

</div>















      {/* <h5 className="font-weight-bold">Note</h5>
      <ol>
        <li>
          Setiap Mahasiswa aktif berhak mengirimkan kritik dan saran pada
          halaman ini.
        </li>
        <li>Silahkan isikan kritik dan saran dengan bahasa yang baik.</li>
        <li>
          Kritik dan saran anda sangat kami harapkan demi kemajuan Manajemen.
        </li>
        <li>
          Identidas pengirim data akan sangat dirahasiakan dan tidak akan
          diketahui oleh siapapun
        </li>
      </ol>
      <form onSubmit={handleSubmit} ref={tagForm}>
        <div className="row">
          <div className="col-md-4">
            <Input
              label="Judul Kritik & Saran *"
              name="judul"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={errors.judul}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="No HP (Optional)"
              name="no_hp"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={errors.no_hp}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Bagian Yang Dituju (Optional)"
              name="bagian"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={errors.bagian}
            />
          </div>
        </div>
        <Textarea
          label="Isi *"
          name="isi"
          onChange={(e) =>
            setForm((prevState) => {
              return { ...prevState, [e.target.name]: e.target.value };
            })
          }
          error={errors.isi}
        />
        <div className="row">
          <div className="col-md-4">
            <img src={img} className="img-fluid" alt="" />
            <Input
              label="Foto (Optional)"
              name="foto"
              type="file"
              onChange={(e) => {
                setImg(URL.createObjectURL(e.target.files[0]));
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.files[0] };
                });
              }}
              error={errors.foto}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-sm btn-success px-3">
          Kirim
        </button>
      </form> */}
    </>
  );
};

export default KsCreate;
