import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patchPeminjamanRuangan } from "../../api";
import Input from "../../components/Input";
// import Modal from "../../components/Modal";
import Select from "../../components/Select";
import Textarea from "../../components/Textarea";
import { saAlert } from "../../helpers";
import { tokenState } from "../../storages/auth";
import { Modal } from "react-bootstrap";

const PeminjamanRuanganEdit = ({ ruangan, peminjamanRuangan, fetchData }) => {
  const token = useRecoilValue(tokenState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(peminjamanRuangan);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await patchPeminjamanRuangan(
        peminjamanRuangan.id,
        form,
        token
      );
      console.log(res.data);
      if (res.data && res.data.status) {
        fetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      {!peminjamanRuangan.mengetahui &&
        !peminjamanRuangan.menyetujui &&
        !peminjamanRuangan.rejected && (
          <>

            <button type="button" onClick={handleOpen} className="btn btn-primary-2 mt-4 w-100">
              <i className="fa fa-fw fa-pen-to-square"></i> Edit ruangan
            </button>

            <Modal
              show={modal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              centered
              contentClassName="border-0 rounded-4"
            >
              <Modal.Header 
                data-bs-theme='dark' 
                className="border-0 score-background shadow rounded-top-4" 
                closeButton
              >
                <Modal.Title className="fs-5 text-white">Edit Data Peminjaman Ruangan</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit} ref={tagForm}>
                  <div className="row">
                    <div className="col-md-4">
                      <Select
                        label="Ruangan"
                        name="ruangan_id"
                        value={form.ruangan_id}
                        onChange={handleChange}
                        error={errors.ruangan_id}
                      >
                        <option value="">Pilih</option>
                        {ruangan &&
                          ruangan.length > 0 &&
                          ruangan.map((r, index) => {
                            return (
                              <React.Fragment key={index}>
                                <option value={r.id}>{r.nama}</option>
                              </React.Fragment>
                            );
                          })}
                      </Select>
                    </div>
                    <div className="col-md-4">
                      <Input
                        label="Dari"
                        type="datetime-local"
                        name="dari"
                        value={form.dari}
                        onChange={handleChange}
                        error={errors.dari}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label="Sampai"
                        type="datetime-local"
                        name="sampai"
                        value={form.sampai}
                        onChange={handleChange}
                        error={errors.sampai}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label="No Telp"
                        name="no_telp"
                        value={form.no_telp}
                        onChange={handleChange}
                        error={errors.no_telp}
                      />
                    </div>
                    <div className="col-md-4">
                      <Textarea
                        label="Keperluan"
                        name="keperluan"
                        value={form.keperluan}
                        onChange={handleChange}
                        error={errors.keperluan}
                      />
                    </div>
                  </div>

                  <div className="btn-group mobile-justify-center rounded-3 mt-3">
                    <button type="submit" className="btn btn-sm btn-primary-2 text-white">
                      Simpan
                    </button>
                    <button type="button" className="btn btn-sm btn-dark text-white" onClick={handleClose}>
                      Kembali
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </>
        )}
    </>
  );
};

export default PeminjamanRuanganEdit;
