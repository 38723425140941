import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showKelas } from "../../api";
import Loading from "../../components/Loading";
import Table, { Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import { getGrade, getHari } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";

const KelasShow = () => {
  const { id } = useParams();
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);

  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await showKelas(id, token);
      setItems(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [id, token]);

  useEffect(() => fetchData(), [fetchData]);

  const setTitle = useSetRecoilState(titleState);
  useEffect(() => setTitle(`Data Kelas ${items.kode}`), [setTitle, items.kode]);

  const getAbsensi = (pertemuan) => {
    let data =
      items.pengajaran &&
      items.pengajaran.length > 0 &&
      items.pengajaran
        .filter((p) => {
          return (
            String(p.pertemuan) === String(pertemuan) &&
            String(p.status) !== "9"
          );
        })
        .map((p) => {
          return p.absensi
            .filter((a) => {
              return String(a.pertemuan) === String(pertemuan);
            })
            .map((a, index) => {
              return (
                <span key={index}>
                  {String(a.status) === "1" && (
                    <>
                      <span className="text-success-light fw-semibold">H</span>
                    </>
                  )}
                  {String(a.status) === "2" && (
                    <>
                      <span className="text-warning fw-semibold">I</span>
                    </>
                  )}
                  {String(a.status) === "3" && (
                    <>
                      <span className="text-warning fw-semibold">S</span>
                    </>
                  )}
                  {String(a.status) === "4" && (
                    <>
                      <span className="text-danger-light fw-semibold">A</span>
                    </>
                  )}
                  {String(a.status) === "" && (
                    <>
                      <span className="text-gray-500 fw-semibold">-</span>
                    </>
                  )}
                </span>
              );
            });
        });

    return data;
  };

  const getPersentasiAbsensi = () => {
    let data =
      items.pengajaran &&
      items.pengajaran.length > 0 &&
      (items.pengajaran.filter((p) => {
        return (
          parseInt(p.status) !== 9 &&
          String(p.pertemuan) !== "UTS" &&
          String(p.pertemuan) !== "UAS" &&
          p.absensi.filter(
            (a) =>
              parseInt(a.mhs_id) === parseInt(user.id) &&
              parseInt(a.status) === 1
          ).length > 0
        );
      }).length /
        12) *
        100;

    if (data) {
      return data.toFixed(2);
    } else return "";
  };

  const getNilai = (jenis) => {
    return (
      items.krs &&
      items.krs.length > 0 &&
      items.krs
        .filter((k) => parseInt(k.mhs_id) === parseInt(user.id))
        .map((k) => {
          if (k.nilai && k.nilai[jenis]) {
            return k.nilai[jenis];
          } else return null;
        })
    );
  };

  // const getNilaiTotal = () => {
  //   return (
  //     parseFloat(
  //       ((getPersentasiAbsensi() * items.persentasi_kehadiran) / 100).toFixed(2)
  //     ) +
  //     parseFloat(
  //       ((getNilai("tugas") * items.persentasi_tugas) / 100).toFixed(2)
  //     ) +
  //     parseFloat(((getNilai("uts") * items.persentasi_uts) / 100).toFixed(2)) +
  //     parseFloat(((getNilai("uas") * items.persentasi_uas) / 100).toFixed(2))
  //   );
  // };

  if (items.id) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
        <LoginAs />
          <div className="row">
            <div className="col-lg-12 my-2 p-2">
              <div className="bg-gradient-primary rounded-3 shadow-nice p-0">
                <div className="score-background rounded-top-3 shadow-nice p-4">
                  <div>
                    <h5 className="my-0 text-warning fst-italic fw-semibold fs-3">
                      {items.kode}
                    </h5>
                  </div>

                  <div className="mb-2 mt-4">
                    <Table className="table-borderless">
                      <Tbody>
                        <Tr>
                          <Td className="text-nowrap text-gray-500">Dosen</Td>
                          <Td className="text-white">:</Td>
                          <Td className="text-white">
                            {items.dosen && items.dosen.nama}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td className="text-nowrap text-gray-500">Ruangan</Td>
                          <Td className="text-white">:</Td>
                          <Td className="text-white">
                            {items.ruangan && items.ruangan.nama}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td className="text-nowrap text-gray-500">
                            Hari / jam
                          </Td>
                          <Td className="text-white">:</Td>
                          <Td className="text-white">
                            {items.jamkul &&
                              `${getHari(items.jamkul.hari)}/${
                                items.jamkul.dari
                              }-${items.jamkul.sampai}`}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td className="text-nowrap text-gray-500">Group WA</Td>
                          <Td className="text-white">:</Td>
                          <Td className="text-white">
                            <button
                              className="btn btn-sm btn-success-2 shadow"
                              onClick={() => {
                                window.open(items.group_whatsapp);
                              }}
                            >
                              <i className="bi bi-whatsapp me-1"></i> Join group
                            </button>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </div>
                </div>

                <div className="row pt-4 g-0">
                  <div className="col-lg-6">
                    <div className="px-4">
                      <h5 className="text-white fs-6">Absensi</h5>

                      <div className="table-responsive">
                        <Table className="table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th className="px-2">Pertemuan</Th>
                              <Th>Keterangan</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td className="text-white text-center">1</Td>
                              <Td className="text-center py-0">{getAbsensi("1")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">2</Td>
                              <Td className="text-center py-0">{getAbsensi("2")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">3</Td>
                              <Td className="text-center py-0">{getAbsensi("3")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">4</Td>
                              <Td className="text-center py-0">{getAbsensi("4")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">5</Td>
                              <Td className="text-center py-0">{getAbsensi("5")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">6</Td>
                              <Td className="text-center py-0">{getAbsensi("6")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">UTS</Td>
                              <Td className="text-center py-0">
                                {getAbsensi("UTS")}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">7</Td>
                              <Td className="text-center py-0">{getAbsensi("7")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">8</Td>
                              <Td className="text-center py-0">{getAbsensi("8")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">9</Td>
                              <Td className="text-center py-0">{getAbsensi("9")}</Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">10</Td>
                              <Td className="text-center py-0">
                                {getAbsensi("10")}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">11</Td>
                              <Td className="text-center py-0">
                                {getAbsensi("11")}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">12</Td>
                              <Td className="text-center py-0">
                                {getAbsensi("12")}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="text-white text-center">UAS</Td>
                              <Td className="text-center py-0">
                                {getAbsensi("UAS")}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="px-4">
                      <h5 className="text-white fs-6">Nilai</h5>

                      <div className="table-responsive">
                        <Table className="table-striped table-bordered">
                          <Thead>
                            <Tr>
                              <Th>Keterangan</Th>
                              <Th className="px-4">Nilai</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                Absensi 
                                {/* ({items.persentasi_kehadiran}%) */}
                              </Td>
                              <Td className="text-white text-center">
                                {getPersentasiAbsensi()}
                              </Td>
                            </Tr>
                            {/* <Tr>
                              <Td className="text-gray-500 text-center">
                                Persentasi Absensi
                              </Td>
                              <Td className="text-white text-center">
                                {(
                                  (getPersentasiAbsensi() *
                                    parseFloat(items.persentasi_kehadiran)) /
                                  100
                                ).toFixed(2)}
                              </Td>
                            </Tr> */}
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                Tugas 
                                {/* ({items.persentasi_tugas}%) */}
                              </Td>
                              <Td className="text-white text-center">
                                {getNilai("tugas")}
                              </Td>
                            </Tr>
                            {/* <Tr>
                              <Td className="text-gray-500 text-center">
                                Persentasi Tugas
                              </Td>
                              <Td className="text-white text-center">
                                {(
                                  (getNilai("tugas") *
                                    parseFloat(items.persentasi_tugas)) /
                                  100
                                ).toFixed(2)}
                              </Td>
                            </Tr> */}
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                UTS 
                                {/* ({items.persentasi_uts}%) */}
                              </Td>
                              <Td className="text-white text-center">
                                {getNilai("uts")}
                              </Td>
                            </Tr>
                            {/* <Tr>
                              <Td className="text-gray-500 text-center">
                                Persentasi UTS
                              </Td>
                              <Td className="text-white text-center">
                                {(
                                  (getNilai("uts") *
                                    parseFloat(items.persentasi_uts)) /
                                  100
                                ).toFixed(2)}
                              </Td>
                            </Tr> */}
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                UAS 
                                {/* ({items.persentasi_uas}%) */}
                              </Td>
                              <Td className="text-white text-center">
                                {getNilai("uas")}
                              </Td>
                            </Tr>
                            {/* <Tr>
                              <Td className="text-gray-500 text-center">
                                Persentasi UAS
                              </Td>
                              <Td className="text-white text-center">
                                {(
                                  (getNilai("uas") *
                                    parseFloat(items.persentasi_uas)) /
                                  100
                                ).toFixed(2)}
                              </Td>
                            </Tr> */}
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                Total
                              </Td>
                              <Td className="text-white text-center">
                                {items.krs
                                  .filter((k) => k.nilai)
                                  .map((k) => {
                                    return k.nilai.total;
                                  })}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="text-gray-500 text-center">
                                Predikat
                              </Td>
                              <Td className="text-warning fw-semibold text-center">
                                {getGrade(
                                  parseInt(
                                    items.krs
                                      .filter((k) => k.nilai)
                                      .map((k) => {
                                        return k.nilai.grade;
                                      })
                                  )
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>

                      <h5 className="text-white fs-6">Catatan</h5>
                      <ul>
                        <li className="text-gray-500 mb-2 text-sm">
                          Nilai sebenarnya dari absensi akan tampil apabila
                          semua pertemuan sudah dilakukan.
                        </li>
                        <li className="text-gray-500 mb-2 text-sm">
                          Nilai{" "}
                          <span className="text-warning text-sm">Tugas</span> ,{" "}
                          <span className="text-warning text-sm">UTS</span> ,
                          dan <span className="text-warning text-sm">UAS</span>{" "}
                          akan tampil apabila dosen yang mengajar sudah
                          menginputkan kedalam sistem.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row g-0 pb-3">
                    <div className="col-lg-4">
                      <div className="px-4">
                        <h5 className="text-white fs-6 mt-2">
                          Keterangan absensi
                        </h5>

                        <div className="table-responsive">
                          <Table className="table-striped table-bordered">
                            <Tbody>
                              <Tr>
                                <Td className="text-success-light fw-semibold text-center">
                                  H
                                </Td>
                                <Td className="text-gray-500 text-center">
                                  Hadir
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-warning fw-semibold text-center">
                                  I
                                </Td>
                                <Td className="text-gray-500 text-center">
                                  Ijin
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-warning fw-semibold text-center">
                                  S
                                </Td>
                                <Td className="text-gray-500 text-center">
                                  Sakit
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-danger-light fw-semibold text-center">
                                  A
                                </Td>
                                <Td className="text-gray-500 text-center">
                                  Alpa
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="px-4">
                        <h5 className="text-white fs-6 mt-2">
                          Keterangan nilai
                        </h5>

                        <div className="table-responsive">
                          <Table className="table-striped table-bordered">
                            <Tbody>
                              <Tr>
                                <Td className="text-gray-500 text-center">
                                  85 s/d 100
                                </Td>
                                <Td className="text-warning fw-semibold text-center">
                                  A
                                </Td>
                                <Td className="text-gray-500">
                                  Sangat Memuaskan
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">{`75 s/d <85`}</Td>
                                <Td className="text-warning fw-semibold text-center">
                                  B
                                </Td>
                                <Td className="text-gray-500">Memuaskan</Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">{`65 s/d <75`}</Td>
                                <Td className="text-warning fw-semibold text-center">
                                  C
                                </Td>
                                <Td className="text-gray-500">Cukup</Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">{`45 s/d <65`}</Td>
                                <Td className="text-warning fw-semibold text-center">
                                  D
                                </Td>
                                <Td className="text-gray-500">
                                  Kurang Memuaskan
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">{`0 s/d <45`}</Td>
                                <Td className="text-warning fw-semibold text-center">
                                  E
                                </Td>
                                <Td className="text-gray-500">Sangat Kurang</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="px-4">
                        <h5 className="text-white fs-6 mt-2">Bobot nilai</h5>

                        <div className="table-responsive">
                          <Table className="table-striped table-bordered">
                            <Tbody>
                              <Tr>
                                <Td className="text-gray-500 text-center">
                                  Nilai Absensi
                                </Td>
                                <Td className="text-white text-center">
                                  {items.persentasi_kehadiran}%
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">
                                  Nilai Tugas
                                </Td>
                                <Td className="text-white text-center">
                                  {items.persentasi_tugas}%
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">
                                  UTS
                                </Td>
                                <Td className="text-white text-center">
                                  {items.persentasi_uts}%
                                </Td>
                              </Tr>
                              <Tr>
                                <Td className="text-gray-500 text-center">
                                  UAS
                                </Td>
                                <Td className="text-white text-center">
                                  {items.persentasi_uas}%
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="btn-group mobile-justify-center px-4 pb-4">
                    <Link to="/krs" className="btn btn-sm btn-dark">
                      Kembali
                    </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default KelasShow;
