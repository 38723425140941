import React from "react";

const Loading = (props) => {
  const { text } = props;
  return (
    // <div className="d-flex justify-content-center align-items-center py-5 my-5 text-secondary">
    //   <i className="fa fa-fw fa-circle-notch fa-spin fs-3 me-2"></i>
    //   {props.title ? <h3 className="my-0"> &nbsp; {title}</h3> : <h3 className="my-0">Loading . . .</h3>}
    // </div>

    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="loadings">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
      <h5 className="text-secondary">{text}</h5>
    </div>
  );
};

export default Loading;
